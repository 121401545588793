div.card {

    display: block;
    width: 100%;
    max-width: 288px;
    height: 288px;
    background: #FFFFFF;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
    border: 2px solid transparent;
    border-radius: 4px;
    background: #ffffff;
    transition: all $transition-fast $transition-effect;

    &.size {

        &--2 {
            max-width: 360px;
        }

        &--3 {
            max-width: 647px;
        }

    }

    &:hover,
    &.hover {
        box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.16);
    }

    &:active,
    &.active,
    &.pressed ,
    &.selected,
    &:disabled,
    &.disabled {
        box-shadow: 0 0 0 rgba(0, 0, 0, 0);
    }

    &.selected {
        border-color: $brand-color-primary-pure;
    }

    &:disabled,
    &.disabled {
        background: $neutral-color-high-medium;
    }

}