label.checkbox {

    display: table;
    position: relative;
    cursor: pointer;
    @extend .noselect;

    div {
        display: inline-flex;
        align-items: center;
        padding-left: 12px;
    }
    
    i {

        display: block;
        position: relative;
        width: 18px;
        height: 18px;
        margin-right: 12px;
        transform: translateY(1px);

        &:before,
        svg {
            display: block;
            position: absolute;
            transition: opacity $transition-fast $transition-effect;
        }

        &:before {
            content: '';
            left: 50%;
            top: 50%;
            width: 42px;
            height: 42px;
            border-radius: 50%;
            background: rgba(0, 0, 0, 0.04);
            transform: translate(-50%,-50%);
            opacity: 0;
        }

        svg {

            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;

            &:nth-child(1){
                opacity: 1;
            }
        
        }
    
    }

    em {
        @extend .noselect;
        font-size: 16px;
        color: $neutral-color-low-dark;
        font-weight: 400;
        font-style: normal;
    }

    input {

        display: none;

        &:checked + div i svg {
            &:nth-child(1) {
                opacity: 0;
            }
            &:nth-child(2) {
                opacity: 1;
            }
        }

        &:indeterminate + div i svg {
            &:nth-child(1) {
                opacity: 0;
            }
            &:nth-child(2) {
                opacity: 0;
            }
            &:nth-child(3) {
                opacity: 1;
            }
        }

        &:disabled {

            cursor: default;
            
            & + div i svg path {
                fill: rgba(0, 0, 0, 0.26);
            }
        
        }

    }

    &.small-text em {

        font-size: 14px;
        line-height: 21px;

        a {

            text-decoration: none;
            color: $brand-color-primary-pure;

            &:hover {
                text-decoration: underline;
            }
        
        }
    
    }

    &:hover:not(.disabled),
    &:focus,
    &.hover { 
        i:before {
            opacity: 1;
        }
    }
    &.checked div i svg {
        &:nth-child(1),
        &:nth-child(3) {
            opacity: 0;
        }
        &:nth-child(2) {
            opacity: 1;
        }
    }
    &.indeterminate div i svg {
        &:nth-child(1),
        &:nth-child(2) {
            opacity: 0;
        }
        &:nth-child(3) {
            opacity: 1;
        }
    }
    &.disabled {

        cursor: default;
        
        div i svg path {
            fill: rgba(0, 0, 0, 0.26);
        }
    
    }

}