div.menu {

    display: block;
    max-width: 360px;

    strong {
        font-size: 14px;
        line-height: 21px;
        font-weight: normal;
        color: $neutral-color-low-pure;
        transform: color $transition-fast $transition-effect;
    }

    svg {

        margin-left: 16px;
        
        path {
            fill: $brand-color-primary-pure;
        }
    
    }

    a,
    button {

        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: auto;
        border: 0;
        box-shadow: none;
        background: transparent;
        padding: 18px 21px 17px 16px; 
        text-decoration: none;
        transform: background-color $transition-fast $transition-effect;

        &:hover {

            background-color: $brand-color-primary-light;
            
            strong {
                color: $brand-color-primary-pure;
            }
        
        }
    
    }

    li {

        display: block;
        border-bottom: 1px solid $neutral-color-high-medium;
        background-color: $neutral-color-high-pure;

        &:first-of-type {
            border-top: 1px solid $neutral-color-high-medium;
        }
    
    }

    ul {
        padding: 0;
        margin: 0;
        list-style: none;
    }

}