div.alerta {

    display: flex;
    flex-wrap: wrap;
    padding: 12px 16px;
    border-radius: 8px;
    background-color: $feedback-color-positive-light;
    transition: 
        margin-top $transition-medium $transition-effect,
        margin-bottom $transition-medium $transition-effect
    ;

    em {
        font-style: normal;
        color: $feedback-color-positive-dark;
        font-size: 14px;
        line-height: 21px;
    }

    button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 21px;
        height: 21px;
        padding: 0;
        margin-left: 12px;
        border: 0;
        background: transparent;
        box-shadow: none;
    }

    &.closeable {
        padding-right: 14px;
    }

    &.color {

        &--2 {

            background-color: $feedback-color-warning-light;

            em {
                color: $feedback-color-warning-dark;
            }

            svg path {
                fill: $feedback-color-warning-dark;
            }
        
        }

        &--3 {

            background-color: $feedback-color-negative-light;

            em {
                color: $feedback-color-negative-dark;
            }

            svg path {
                fill: $feedback-color-negative-dark;
            }
        
        }

    }

    &.closing {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
    }

}