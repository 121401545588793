div.chip {

	display: inline-flex;
	align-items: center;
	border: 1px solid transparent;
	height: $button-height-md;
	padding: 0 12px;
	border-radius: calc( $button-height-md / 2 );
	line-height: calc( $button-height-md - 2px );

	strong {
		display: block;
		font-size: 14px;
		font-weight: 400;
	}

	button {

		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0;
		border: 0;
		background: none;
		border-radius: 50%;
		box-shadow: none;

		&.heart {
			width: 24px;
			height: 24px;
			margin-right: 8px;
		}
	
		&.close {
			width: 20px;
			height: 20px;
			margin-left: 10px;
		}
	
	}

	&.heart {
		padding-left: 4px;
	}
	&.close {
		padding-right: 6px;
	}

	&.primary {

		color: $neutral-color-high-pure;
		background-color: $brand-color-primary-pure;

		button {

			&.heart {
				background-color: $brand-color-primary-dark;
			}

		}
	
	}

	&.secondary {

		color: $neutral-color-low-dark;
		background-color: $neutral-color-high-medium;

		button {

			&.heart {

				background-color: $neutral-color-high-dark;

				path {
					fill: $neutral-color-low-light;
				}
			
			}

			&.close path {
				fill: $neutral-color-low-light;
			}

		}
	
	}

	&.outlined {

		background-color: transparent;

		&.primary {

			border-color: $brand-color-primary-medium;
			color: $brand-color-primary-pure;

			button.close path {
				fill: $brand-color-primary-medium;
			}

		}

		&.secondary {

			border-color: $neutral-color-low-light;
			color: $neutral-color-low-dark;

			button.close path {
				fill: $neutral-color-low-light;
			}

		}

	}

	&.size--2 {

		height: $button-height-sm;
		border-radius: calc( $button-height-sm / 2 );
		line-height: calc( $button-height-sm - 2px );

		button {

			&.heart {

				width: 16px;
				height: 16px;

				svg {
					width: 7px;
					height: 6px;
				}
			
			}
		
			&.close {
				width: 14px;
				height: 14px;
				margin-left: 9px;
			}

		}

	}

}