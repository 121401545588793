div.field-input {
    
    width: 220px;
    padding-top: 10px;

    ::placeholder {
        color: $neutral-color-low-medium;
        opacity: 1;
    }
    :-ms-input-placeholder {
        color: $neutral-color-low-medium;
    }
    ::-ms-input-placeholder {
        color: $neutral-color-low-medium;
    }

    > span {

        position: relative;
        display: flex;
        align-items: center;
        width: 100%;
        height: 56px;
        background: #fff;

        &:before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 4px;
            border: 1px solid $neutral-color-high-dark;
            transition: border-color .4s $transition-effect;
            z-index: 1;
        }

    }

    input,
    label,
    button.suffix,
    span.suffix
    .prefix,
    .suffix {
        z-index: 2;
    }

    label {
        @extend .noselect;
        position: absolute;
        top: 50%;
        left: 9px;
        width: auto;
        padding: 0 6px;
        transform: translateY(-50%);
        transition: all 300ms;
        background-color: inherit;
        cursor: default;
        color: $neutral-color-low-medium;
    }

    input,
    textarea {

        width: 100%;
        height: 100%;
        outline: none;
        font-size: 16px;
        border: 0;
        background: transparent;
        transition:
            line-height .4s $transition-effect,
            padding .4s $transition-effect
        ;

        &:not([type="checkbox"]):not([type="radio"]){
            width: 100%;
        }
    
    }

    input,
    textarea {
        flex: 1;
    }
    input {
        padding: 0 12px 0 12px;
        line-height: 54px;
    }

    button.suffix,
    span.suffix {

        display: block;
        position: relative;
        width: 22px;
        height: 22px;
        overflow: hidden;
        text-indent: -99999px;

        &:before,
        &:after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-repeat: no-repeat;
            background-position: center center;
            transition: opacity $transition-fast $transition-effect;

        }

    }

    button.toggle-eye,
    span.toggle-eye {
        &:before {
            @extend .eye-off;
        }
        &:after {
            @extend .eye-on;
            opacity: 0;
        }
        &.active {
            &:before {
                opacity: 0;
            }
            &:after {
                opacity: 1;
            }
        }
    }

    .prefix {
        padding: 0 0 0 14px;
        font-size: 16px;
        line-height: 24px;
        color: $neutral-color-low-medium;
    }
    
    .suffix {
        padding: 0;
        margin: 0 10px;
        background: transparent;
        border: 0;
        box-shadow: none;
    }

    .field-description {
        display: block;
        width: 100%;
        padding: 0 15px;
        font-size: 12px;
        line-height: 18px;
        margin-top: 2px;
        color: $neutral-color-low-medium;
    }

    .password-strenght {

        display: block;
        padding: 0;
        margin: 16px 0 0;
        list-style: none;

        li {

            position: relative;
            padding-left: 33px;
            font-size: 14px;
            color: $neutral-color-low-medium;

            &:before,
            &:after {
                content: '';
                display: block;
                position: absolute;
                transition: opacity $transition-fast $transition-effect;
            }
            &:before {
                left: 8px;
                top: 5px;
                width: 8px;
                height: 8px;
                border-radius: 50%;
                background-color: $brand-color-primary-pure;
                opacity: 0.24;
            }
            &:after {
                @extend .check-blue;
                left: 5px;
                top: 3px;
                width: 16px;
                height: 12px;
                opacity: 0;
            }

            &:not(:first-of-type){
                margin-top: 11px;
            }

            &.checked,
            &.force-checked {
                &:before {
                    opacity: 0;
                }
                &:after {
                    opacity: 1;
                }
            }
        
        }
    
    }

    &.focus,
    &.force-focus,
    &.filled,
    &.force-ffilled,
    &.with-prefix,
    &.with-suffix {
        label {
            font-size: 0.8em;
            top: -2px;
            opacity: 1;    
            color: $neutral-color-low-medium;
        }
    }
    &.focus,
    &.force-focus {
        label {
            color: $brand-color-primary-pure;
        }
    }
    &.focus:not(.error),
    &.force-focus:not(.error) {

        & > span:before { 
            border: 2px solid $brand-color-primary-pure;
        }
    
        input {
            height: 52px;
            line-height: 52px;
        }
    
    }
    &.filled,
    &.force-filled {
        input,
        textarea {
            color: $neutral-color-low-dark;
        }
    }

    &:not(.filled) {
        color: $neutral-color-low-dark;
    }

    &.with-prefix {
        input,
        textarea {
            padding-left: 10px;
        }
    }

    &.disabled {

        input, 
        textarea, 
        label, 
        em.prefix {
            @extend .noselect;
            color: $neutral-color-high-dark;
        }

        span.suffix,
        button.suffix {
            opacity: 0.4;
            cursor: default;
        }
    
    }

    &.error {

        & > span:before { 
            border: 1px solid $feedback-color-negative-pure;
        }

        &.focus,
        &.force-focus,
        &.filled,
        &.force-ffilled {
            label {
                color: $feedback-color-negative-pure;
            }
        }
    
    }

}