.no-scroll {
    overflow: hidden !important;
}

.bbpvd-modal-inner {
    
    display: block;
    position: relative;
    width: 100vw;
    max-width: 450px;

    .close-button.native {
        position: absolute;
        top: 8px;
        right: 6px;
        width: 40px;
        height: 40px;
        overflow: hidden;
        text-indent: -99999px;
        box-shadow: none;
        background: no-repeat center center;
        background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxMiAxMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTYuOTQwMDQgNi4wMDAwMkwxMS4xNCAxLjgwNjY4QzExLjI2NTYgMS42ODExNSAxMS4zMzYxIDEuNTEwODggMTEuMzM2MSAxLjMzMzM1QzExLjMzNjEgMS4xNTU4MiAxMS4yNjU2IDAuOTg1NTUzIDExLjE0IDAuODYwMDE3QzExLjAxNDUgMC43MzQ0ODEgMTAuODQ0MiAwLjY2Mzk1NiAxMC42NjY3IDAuNjYzOTU2QzEwLjQ4OTIgMC42NjM5NTYgMTAuMzE4OSAwLjczNDQ4MSAxMC4xOTM0IDAuODYwMDE3TDYuMDAwMDQgNS4wNjAwMkwxLjgwNjcxIDAuODYwMDE3QzEuNjgxMTcgMC43MzQ0ODEgMS41MTA5MSAwLjY2Mzk1NiAxLjMzMzM3IDAuNjYzOTU2QzEuMTU1ODQgMC42NjM5NTYgMC45ODU1NzYgMC43MzQ0ODEgMC44NjAwNDEgMC44NjAwMTdDMC43MzQ1MDUgMC45ODU1NTMgMC42NjM5OCAxLjE1NTgyIDAuNjYzOTggMS4zMzMzNUMwLjY2Mzk4IDEuNTEwODggMC43MzQ1MDUgMS42ODExNSAwLjg2MDA0MSAxLjgwNjY4TDUuMDYwMDQgNi4wMDAwMkwwLjg2MDA0MSAxMC4xOTMzQzAuNzk3NTU1IDEwLjI1NTMgMC43NDc5NTkgMTAuMzI5MSAwLjcxNDExMyAxMC40MTAzQzAuNjgwMjY3IDEwLjQ5MTUgMC42NjI4NDIgMTAuNTc4NyAwLjY2Mjg0MiAxMC42NjY3QzAuNjYyODQyIDEwLjc1NDcgMC42ODAyNjcgMTAuODQxOCAwLjcxNDExMyAxMC45MjMxQzAuNzQ3OTU5IDExLjAwNDMgMC43OTc1NTUgMTEuMDc4IDAuODYwMDQxIDExLjE0QzAuOTIyMDE2IDExLjIwMjUgMC45OTU3NSAxMS4yNTIxIDEuMDc2OTkgMTEuMjg1OUMxLjE1ODIzIDExLjMxOTggMS4yNDUzNyAxMS4zMzcyIDEuMzMzMzcgMTEuMzM3MkMxLjQyMTM4IDExLjMzNzIgMS41MDg1MiAxMS4zMTk4IDEuNTg5NzYgMTEuMjg1OUMxLjY3MSAxMS4yNTIxIDEuNzQ0NzMgMTEuMjAyNSAxLjgwNjcxIDExLjE0TDYuMDAwMDQgNi45NDAwMkwxMC4xOTM0IDExLjE0QzEwLjI1NTQgMTEuMjAyNSAxMC4zMjkxIDExLjI1MjEgMTAuNDEwMyAxMS4yODU5QzEwLjQ5MTYgMTEuMzE5OCAxMC41Nzg3IDExLjMzNzIgMTAuNjY2NyAxMS4zMzcyQzEwLjc1NDcgMTEuMzM3MiAxMC44NDE5IDExLjMxOTggMTAuOTIzMSAxMS4yODU5QzExLjAwNDMgMTEuMjUyMSAxMS4wNzgxIDExLjIwMjUgMTEuMTQgMTEuMTRDMTEuMjAyNSAxMS4wNzggMTEuMjUyMSAxMS4wMDQzIDExLjI4NiAxMC45MjMxQzExLjMxOTggMTAuODQxOCAxMS4zMzcyIDEwLjc1NDcgMTEuMzM3MiAxMC42NjY3QzExLjMzNzIgMTAuNTc4NyAxMS4zMTk4IDEwLjQ5MTUgMTEuMjg2IDEwLjQxMDNDMTEuMjUyMSAxMC4zMjkxIDExLjIwMjUgMTAuMjU1MyAxMS4xNCAxMC4xOTMzTDYuOTQwMDQgNi4wMDAwMloiIGZpbGw9IiMwMDYwQTkiLz4KPC9zdmc+Cg==);
    }

    .center-content {

        padding-top: 24px;

        &,
        p {
            color: $neutral-color-low-medium;
        }
    
    }

    .scroller {

        > nav {

            display: flex;
            align-items: flex-end;
            justify-content: flex-end;
            margin-top: 48px;

            button,
            a {
                &:not(:last-child){
                    margin-right: 8px;
                }
            }
        
        }

        > .icon {
            display: block;
            height: 40px;
            width: auto;
        }
    
        pre {
            text-align: left !important;
        }
    
    }

    .modal-title {
        display: block;
        font-size: 20px;
        line-height: 24px;
        font-weight: 700;
    }

    .content {

        display: block;
        padding: 16px 24px 24px;
        background: #FFFFFF;
        box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.16);
        border-radius: 4px;

        header {

            display: block;
            position: relative;
            padding-left: 148px;

            &:before {
                content: '';
                display: block;
            }

        }

        > div {

            p {

                margin: 0 0 21px 0;
                font-size: 16px;
                line-height: 20px;

                &:last-child {
                    margin-bottom: 0;
                }
            
            }

        }

    }

    .mCSB_scrollTools {

        width: 6px;

        .mCSB_dragger .mCSB_dragger_bar {
            width: 2px;
        }

        .mCSB_draggerRail {
            opacity: 0 !important;
            background-color: transparent !important;
        }

        &_vertical.mCSB_scrollTools_onDrag_expand {
            .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar, 
            .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar{ 
                width: 6px;
            }
        }

        &.mCS_no_scrollbar {

        }

    }

    .mCSB_outside {
        & > .mCSB_container { margin-right: 24px; }
        & + .mCSB_scrollTools { right: -6px; }
    }

    &.align-center {

        .center-content,
        .modal-title {
            text-align: center;
        }

        .scroller {

            > .icon {
                margin: 0 auto;
            }
        
            > nav {
                justify-content: center;
            }
        
        }

    }

}

.bbpvd-modal {

    display: flex;
    position: fixed;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 0 30px;
    overflow-x: hidden;
    overflow-y: auto;
    background: rgba( 0, 0, 0, 0.6 );
    z-index: 99999;
    opacity: 0;
    transform: translateX(-110%);
    transition: 
        opacity $transition-fast $transition-effect, 
        transform $transition-fast $transition-effect $transition-fast
    ;
    
    &:before,
    &:after,
    > .content {
        width: 100%;
    }

    &:before,
    &:after {
        content: '';
        display: block;
        flex: 1;
        min-height: 30px;
    }

    &.opened {
        transition: 
            opacity $transition-medium $transition-effect, 
            transform $transition-medium $transition-effect
        ;
        opacity: 100;
        transform: translateX(0);
    }

    &:not(.fit-screen){
        .content {
            display: block;
            width: 100vw;
            max-width: 450px;
            padding: 16px 24px 24px;
            background: #FFFFFF;
            box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.16);
            border-radius: 4px;
        }
    }

    &.fit-screen {

        overflow-y: hidden;

        .bbpvd-modal-inner {

            display: flex;
            max-width: 900px;
            flex-direction: column;
            justify-content: center;
            padding: 43px 33px;
            background-color: #171717;
            max-height: calc( 100vh - 60px );

            > nav {
                padding-top: 20px;
            }
        
        }

        .modal-title {

            display: flex;
            font-style: italic;
            justify-content: flex-start;
            padding-bottom: 17px;

            span {
                font-weight: 900;
                font-size: 20px;
                line-height: 27px;
                text-transform: uppercase;
                color: #FAFAFA;
            }

            strong {
                flex: 1;
                padding-top: 13px;
                margin: 19px 0 0 21px;
                font-weight: 900;
                font-size: 24px;
                line-height: 132%;
                color: #1A5AEC;
                border-top: 2px solid #FAFAFA;
            }
        
        }

        .content {

            position: relative;
            max-width: 100%;
            height: 100%;
            max-height: 100%;
            color: #fff;
            padding: 48px 30px 26px 30px;
            opacity: 0.8;
            border: 0.5px solid #CCCCCC;

            p, ul, li, blockquote, div {
                text-align: left; 
            }

            > .scroller {

                display: block;
                position: relative;
                width: 100%;
                height: 100%;
                overflow-y: auto;
                overflow-x: hidden;
                text-align: left;
            
            }

        }
    
    }

}

body.wp-admin #modal {

    .scroller > nav {

        display: flex;
        justify-content: center;

        button, a {
            margin: 0 5px;
        }

    }

    .close-button.native {
        
        top: 20px;
        right: 20px;
        width: 30px;
        height: 30px;
        border: 0;
        background: none;
        overflow-y: hidden;
        text-indent: -99999px;
        cursor: pointer;

        &:before,
        &:after {
            content: '';
            display: block;
            position: absolute;
            left: 2px;
            top: 12px;
            width: 25px;
            height: 3px;
            background: grey;
            border-radius: 2px;
            transition: all 0.25s;
        }
        &:before {
            transform: rotate(45deg);
        }
        &:after {
            transform: rotate(-45deg);
        }

        &:hover {
            &:before,
            &:after {
                background-color: black;
            }
        }

    }

    &.confirm .scroller {
        text-align: center;
    }

}

@media( max-width: 958px ){

    #modal.fit-screen .modal-title {

        flex-direction: column;

        strong {
            margin-left: 0;
        }
    
    }

}

@media( max-width: 768px ){

    #modal.fit-screen .modal-title {

        strong {
            font-size: 20px;
            line-height: 132%;
        }
    
    }

}