.icon {

    display: table;
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;

    // Azul padrão #0060A9
    filter: invert(20%) sepia(37%) saturate(6556%) hue-rotate(191deg) brightness(93%) contrast(104%);

    &.color {
        // Preto #000000
        &--2 {
            filter: invert(0%) sepia(1%) saturate(36%) hue-rotate(225deg) brightness(95%) contrast(100%);
        }
        // Branco #FFFFFF
        &--3 {
            filter: invert(98%) sepia(2%) saturate(845%) hue-rotate(212deg) brightness(117%) contrast(100%);
        }
        // Azul marinho escuro #001F37
        &--4 {
            filter: invert(12%) sepia(16%) saturate(4717%) hue-rotate(173deg) brightness(98%) contrast(108%);
        }
        // Azul medium #66A0CB
        &--5 {
            filter: invert(68%) sepia(8%) saturate(3317%) hue-rotate(174deg) brightness(92%) contrast(70%);
        }
        // Cinza disabled #66A0CB
        &--6 {
            filter: invert(88%) sepia(0%) saturate(0%) hue-rotate(180deg) brightness(95%) contrast(89%);
        }
        // Azul bebê
        &--7 {
            filter: invert(100%) sepia(46%) saturate(2111%) hue-rotate(176deg) brightness(103%) contrast(102%);
        }
        // Cinza
        &--8 {
            filter: invert(74%) sepia(0%) saturate(0%) hue-rotate(161deg) brightness(89%) contrast(88%);
        }
        // Cinza escuro
        &--9 {
            filter: invert(43%) sepia(1%) saturate(0%) hue-rotate(330deg) brightness(91%) contrast(92%);
        }
        // Success
        &--success {
            filter: invert(54%) sepia(48%) saturate(475%) hue-rotate(95deg) brightness(93%) contrast(98%);
        }
        // Warning
        &--warning {
            filter: invert(81%) sepia(54%) saturate(1859%) hue-rotate(326deg) brightness(111%) contrast(110%);
        }
        // Error
        &--error {
            filter: invert(25%) sepia(26%) saturate(6958%) hue-rotate(350deg) brightness(100%) contrast(75%);
        }
        &--highlight {
            filter: invert(68%) sepia(40%) saturate(2738%) hue-rotate(294deg) brightness(99%) contrast(95%);
        }

    }

    &.size {
        &--xs {
            width: 8px;
            height: 8px;
        }
        &--s {
            width: 16px;
            height: 16px;
        }
        &--m {
            width: 24px;
            height: 24px;
        }
        &--l {
            width: 32px;
            height: 32px;
        }
        &--xl {
            width: 40px;
            height: 40px;
        }
        &--xxl {
            width: 48px;
            height: 48px;
        }
    }

}
