div.buttons-group {

	display: inline-flex;

	button,
	a.button {

		border-radius: 0;

		&.left:before, 
		&.right:after, 
		&.square:before {
			width: 8px;
			height: 11px;
			@extend .angle-button;
		}

		&.outlined {

			&.primary:not(:disabled) {
				&:before,
				&:after {
					@extend .angle-button-blue;
				}
			}

			&:disabled {
				&:after,
				&:before {
					@extend .angle-button-grey;
				}
			}
		
		}

		&.text {
			border-radius: 0;
		}
	
		&:disabled {

			background: $neutral-color-high-pure;
			color: $neutral-color-high-dark;

			&.left:before, 
			&.right:after, 
			&.square:before {
				@extend .angle-button-grey;
			}

		}

	}

	&:not(.vertical){
		
		button,
		a.button {

			&:first-of-type {
				border-radius: 4px 0 0 4px; 
			}
			&:last-of-type {
				border-radius: 0 4px 4px 0; 
			}
			&:not(:last-of-type){
				border-right-color: $neutral-color-high-light;
			}

			&.outlined:not(:last-child){
				border-right-color: transparent;
			}
			&.text:not(.vertical):not(:last-of-type){
				border-right: $brand-color-primary-pure 1px solid !important;
			}

			&:disabled {

				box-shadow: none;
		
				&:not(:last-of-type) {
					border-right-color: $neutral-color-high-dark;
				}
				&:not(:first-of-type) {
					border-left-color: transparent;
				}

				&.text:not(.vertical):not(:last-of-type){
					border-right: $neutral-color-high-dark 1px solid !important;
				}

			}

		}

	}

	&.vertical {

		flex-direction: column;

		button,
		a.button {

			&:first-of-type {
				border-radius: 4px 4px 0 0; 
			}
			&:last-of-type {
				border-radius: 0 0 4px 4px; 
			}
			&:not(:last-of-type){
				border-bottom-color: $brand-color-primary-light;
			}

			&.text:not(:last-of-type){
				border-bottom: $brand-color-primary-pure 1px solid !important;
			}
			&.square {
				width: initial;
			}

			&:disabled {

				&:not(:last-of-type){
					border-bottom-color: $neutral-color-high-dark;
				}
				&:not(:first-of-type){
					border-top-color: transparent;
				}
				&.text:not(:last-of-type){
					border-bottom-color: $neutral-color-high-dark !important;
				}

			}

		}
	
	}

}