h1, h2, h3, h4, h5, h6 {
	font-family: $font-family-highlight;
	line-height: $line-height-default;
}
h1, h2 {
	font-weight: $font-weight-black;
}
h1 {
	font-size: $font-size-giant;
}
h2 {
	font-size: $font-size-xxxl;
}
h3, h4, h5, h6 {
	font-weight: $font-weight-bold;
}
h3 {
	font-size: $font-size-xxl;
}
h4 {
	font-size: $font-size-lg;
}
h5 {
	font-size: $font-size-md;
}
h6 {
	font-size: $font-size-xs;
}
em, p, figcaption, caption {
	font-family: $font-family-highlight;
	font-weight: $font-weight-regular;
}
em {

	font-style: normal;
	font-size: $font-size-sm;
	line-height: $line-height-lg;

	&.small {
		font-size: $font-size-md;
	}

}
p {

	font-size: $font-size-md;
	line-height: $line-height-lg;

	&.small {
		font-size: $font-size-sm;
	}
 
}
figcaption,
caption,
.caption {

	font-size: $font-size-sm;
	line-height: $line-height-lg;

	&.small {
		font-size: $font-size-xs;
	}
 
}

@media( max-width: $media-phone-max ){

	h1 {
		font-size: $font-size-xxxl;
	}
	h2 {
		font-size: $font-size-xxl;
	}
	h3 {
		font-size: $font-size-xl;
	}
	h4 {
		font-size: $font-size-md;
	}
	h5 {
		font-size: $font-size-sm;
	}
	h6 {
		font-size: $font-size-xs;
	}
	em {

		font-size: $font-size-md;
		line-height: $line-height-sm;

		&.small {
			font-size: $font-size-sm;
		}

	}

}