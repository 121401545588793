div.field-slider {

    .noUi {

        &-target {

            background: transparent;
            border-radius: 0;
            border: 0;
            box-shadow: none;

            &:before {
                content: '';
                display: block;
                position: absolute;
                top: 15px;
                width: 100%;
                height: 2px;
                border-radius: 2px;
                background: $brand-color-primary-light;
            }
        
        }

        &-horizontal {

            height: 32px;

            .noUi-handle {
                top: -15px;
                width: 32px;
                height: 32px;
                border-radius: 50%;
            }
        
        }

        &-connect {
            background: $brand-color-primary-pure;
        }

        &-handle {

            border: 0;
            box-shadow: none;
            background: rgba( 0, 96, 169, 0 );
            transition: background $transition-fast $transition-effect;

            &.noUi-active {
                background: rgba( 0, 96, 169, 0.16 ); 
                cursor: col-resize;
            }

            &:before {
                left: 50%;
                top: 50%;
                width: 12px;
                height: 12px;
                border-radius: 50%;
                background: $brand-color-primary-pure;
                transform: translate(-50%,-50%);
                box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.4);
            }

            &:after {
                display: none;
            }
        
        }

        &-base, 
        &-connects {
            height: 2px;
        }
        &-base {
            top: 15px;
        }
        
        &-tooltip {

            bottom: calc( 100% - 2px );
            border-radius: 4px;
            border: 1px solid transparent;
            background: $neutral-color-low-medium;
            color: $neutral-color-high-pure;
            font-size: 16px;
            opacity: 0;
            transition: opacity $transition-fast $transition-effect;

            &:after {
                content: '';
                display: block;
                position: absolute;
                top: 100%;
                left: 50%;
                width: 12px;
                height: 6px;
                border-top: 6px solid $neutral-color-low-medium;
                border-left: 6px solid transparent;
                border-right: 6px solid transparent;
                transform: translateX(-50%);
            }
        
        }
        &-active .noUi-tooltip {
            opacity: 1;
        }

        &-pips-horizontal {
        
            top: calc( 50% - 1px );
            height: auto;
            padding: 0;

            .noUi-value {

                font-size: 14px;
                color: $neutral-color-low-medium;

                &-horizontal {

                    margin-top: 24px;
                    transform: translateX(-50%);

                    &:nth-child(1),
                    &:nth-child(2){
                        transform: none;
                    }
                
                }

                &:last-of-type {
                    left: initial !important;
                    right: 0;
                    transform: none;
                }
            
            }
        
        }
    
        &-marker-horizontal.noUi-marker {
        
            &,
            &-large {
                height: 2px;
                background: $brand-color-primary-pure;
            }
        
        }

    }

    &[data-discrete="true"]{
        padding-top: 18px;
        padding-bottom: 22px;
    }

    &.secondary .noUi {

        &-target:before {
            background: $brand-color-secondary-light;
        }

        &-connect {
            background: $brand-color-secondary-pure;
        }

        &-handle {

            &.noUi-active {
                background: rgba( 255, 242, 0, 0.16 ); 
            }

            &:before {
                background: $brand-color-secondary-pure;
            }
        
        }
    
        &-marker-horizontal.noUi-marker {
        
            &,
            &-large {
                background: $brand-color-secondary-pure;
            }
        
        }
    }

    &.pressed {

        .noUi-handle {
            background: rgba( 0, 96, 169, 0.16 );
        }

        &.secondary .noUi-handle {
            background: rgba( 255, 242, 0, 0.16 ); 
        }
        
    }

    &.disabled {

        .noUi {

            &-handle {
                
                background: transparent !important;
            
                &:before {
                    background: $neutral-color-high-dark !important;
                }
            }

            &-connect,
            &-target:before {
                background: $neutral-color-high-dark !important;
            }

            &-pips-horizontal {
                display: none !important;
            }
        
        }

    }

    &.medium {

        .noUi {

            &-base, 
            &-connects {
              height: 4px;
            }

            &-base {
                top: 20px;
            }

            &-handle {

                width: 42px;
                height: 42px;

                &:before {
                    width: 20px;
                    height: 20px;
                }
            
            }

            &-target:before {
                height: 4px;
                border-radius: 2px;
            }

            &-horizontal {

                height: 42px;

                .noUi-handle {
                    top: -20px;
                }
            
            }

            &-target:before {
                top: 20px;
            }

            &-pips-horizontal {
                top: calc(50% - 0px);
            }
        
        }

    }

}