.page-state {

    display: flex;
    flex-direction: column;
    align-items: center;

    em {
        font-size: 16px;
        line-height: 19px;
        margin-top: 32px;
    }

    > span {

        display: block;
        width: 100%;
        max-width: 256px;
        position: relative;
        margin: 0 auto;

        > span {

            display: block;
            position: relative;
            width: 100%;
            height: 0;

            &:before {
                content: '';
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-repeat: no-repeat;
                background-position: center center;
                background-size: contain;
            }        

        }
    
    }

    &.error-empty > span {

        > span {
            
            padding-top: 46.875%;

            &:before {
                background-image: url('../images/error-empty.png');
                background-image: -webkit-image-set(
                    url('../images/error-empty.png') 1x,
                    url('../images/error-empty@2x.png') 2x,
                );
                background-image: image-set(
                    url('../images/error-empty.png') 1x,
                    url('../images/error-empty@2x.png') 2x,
                );
            }

        }
    
    }

    &.error-404 > span {

        > span {
            
            padding-top: 62.5%;

            &:before {
                background-image: url( '../images/error-404.png' );
                background-image: -webkit-image-set(
                    url('../images/error-404.png') 1x,
                    url('../images/error-404@2x.png') 2x,
                );
                background-image: image-set(
                    url('../images/error-404.png') 1x,
                    url('../images/error-404@2x.png') 2x,
                );
            }

        }
    
    }

    &.error-no-connection > span {

        > span {
            
            padding-top: 53.125%;

            &:before {
                background-image: url( '../images/error-no-connection.png' );
                background-image: -webkit-image-set(
                    url('../images/error-no-connection.png') 1x,
                    url('../images/error-no-connection@2x.png') 2x,
                );
                background-image: image-set(
                    url('../images/error-no-connection.png') 1x,
                    url('../images/error-no-connection@2x.png') 2x,
                );
            }

        }
    
    }

    &.choice-plan > span {

        > span {
            
            padding-top: 73.046875%;

            &:before {
                background-image: url( '../images/choice-plan.png' );
                background-image: -webkit-image-set(
                    url('../images/choice-plan.png') 1x,
                    url('../images/choice-plan@2x.png') 2x,
                );
                background-image: image-set(
                    url('../images/choice-plan.png') 1x,
                    url('../images/choice-plan@2x.png') 2x,
                );
            }

        }
    
    }

    &.password-update-success > span {

        > span {
            
            padding-top: 65.625%;

            &:before {
                background-image: url( '../images/password-update-success.png' );
                background-image: -webkit-image-set(
                    url('../images/password-update-success.png') 1x,
                    url('../images/password-update-success@2x.png') 2x,
                );
                background-image: image-set(
                    url('../images/password-update-success.png') 1x,
                    url('../images/password-update-success@2x.png') 2x,
                );
            }

        }
    
    }

    &.password-new > span {

        > span {
            
            padding-top: 67.96875%;

            &:before {
                background-image: url( '../images/password-new.png' );
                background-image: -webkit-image-set(
                    url('../images/password-new.png') 1x,
                    url('../images/password-new@2x.png') 2x,
                );
                background-image: image-set(
                    url('../images/password-new.png') 1x,
                    url('../images/password-new@2x.png') 2x,
                );
            }

        }
    
    }

    &.password-recover > span {

        > span {
            
            padding-top: 68.75%;

            &:before {
                background-image: url( '../images/password-recover.png' );
                background-image: -webkit-image-set(
                    url('../images/password-recover.png') 1x,
                    url('../images/password-recover@2x.png') 2x,
                );
                background-image: image-set(
                    url('../images/password-recover.png') 1x,
                    url('../images/password-recover@2x.png') 2x,
                );
            }

        }
    
    }

    &.password-sms > span {

        > span {
            
            padding-top: 68.75%;

            &:before {
                background-image: url( '../images/password-sms.png' );
                background-image: -webkit-image-set(
                    url('../images/password-sms.png') 1x,
                    url('../images/password-sms@2x.png') 2x,
                );
                background-image: image-set(
                    url('../images/password-sms.png') 1x,
                    url('../images/password-sms@2x.png') 2x,
                );
            }

        }
    
    }

    &.big {

        &.error-empty > span > span:before {
            background-image: url('../images/error-empty@2x.png');
            background-image: -webkit-image-set(
                url('../images/error-empty@2x.png') 1x,
                url('../images/error-empty@4x.png') 2x,
            );
            background-image: image-set(
                url('../images/error-empty@2x.png') 1x,
                url('../images/error-empty@4x.png') 2x,
            );
        }

        &.error-404 > span > span:before {
            background-image: url( '../images/error-404@2x.png' );
            background-image: -webkit-image-set(
                url('../images/error-404@2x.png') 1x,
                url('../images/error-404@4x.png') 2x,
            );
            background-image: image-set(
                url('../images/error-404@2x.png') 1x,
                url('../images/error-404@4x.png') 2x,
            );
        }

        &.error-no-connection > span > span:before {
            background-image: url( '../images/error-no-connection@2x.png' );
            background-image: -webkit-image-set(
                url('../images/error-no-connection@2x.png') 1x,
                url('../images/error-no-connection@4x.png') 2x,
            );
            background-image: image-set(
                url('../images/error-no-connection@2x.png') 1x,
                url('../images/error-no-connection@4x.png') 2x,
            );
        }

        &.choice-plan > span > span:before {
            background-image: url( '../images/choice-plan@2x.png' );
            background-image: -webkit-image-set(
                url('../images/choice-plan@2x.png') 1x,
                url('../images/choice-plan@4x.png') 2x,
            );
            background-image: image-set(
                url('../images/choice-plan@2x.png') 1x,
                url('../images/choice-plan@4x.png') 2x,
            );
        }

        &.password-update-success > span > span:before {
            background-image: url( '../images/password-update-success@2x.png' );
            background-image: -webkit-image-set(
                url('../images/password-update-success@2x.png') 1x,
                url('../images/password-update-success@4x.png') 2x,
            );
            background-image: image-set(
                url('../images/password-update-success@2x.png') 1x,
                url('../images/password-update-success@4x.png') 2x,
            );
        }

        &.password-new > span > span:before {
            background-image: url( '../images/password-new@2x.png' );
            background-image: -webkit-image-set(
                url('../images/password-new@2x.png') 1x,
                url('../images/password-new@4x.png') 2x,
            );
            background-image: image-set(
                url('../images/password-new@2x.png') 1x,
                url('../images/password-new@4x.png') 2x,
            );
        }

        &.password-recover > span > span:before {
            background-image: url( '../images/password-recover@2x.png' );
            background-image: -webkit-image-set(
                url('../images/password-recover@2x.png') 1x,
                url('../images/password-recover@4x.png') 2x,
            );
            background-image: image-set(
                url('../images/password-recover@2x.png') 1x,
                url('../images/password-recover@4x.png') 2x,
            );
        }

        &.password-sms > span > span:before {
            background-image: url( '../images/password-sms@2x.png' );
            background-image: -webkit-image-set(
                url('../images/password-sm@2x.png') 1x,
                url('../images/password-sm@4x.png') 2x,
            );
            background-image: image-set(
                url('../images/password-sm@2x.png') 1x,
                url('../images/password-sm@4x.png') 2x,
            );
        }

    }

}