div.avatar {

	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 40px;
	height: 40px;
	background-color: $neutral-color-high-dark;

	strong,
	img,
	i {
		display: block;
	}
	img:not(.icon) {
		display: block;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	strong {
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 20px;
		line-height: 24px;
		font-weight: 400;
		color: $neutral-color-high-pure;
	}

	&.badge:before {
		content: '';
		display: block;
		position: absolute;
		right: -4px;
		bottom: -4px;
		width: 12px;
		height: 12px;
		background: $feedback-color-positive-pure;
		border: 2px solid $neutral-color-high-pure;
		border-radius: 50%;
	}
	&.warning.badge:before {
		background-color: $feedback-color-warning-pure;
	}
	&.negative.badge:before {
		background-color: $feedback-color-negative-pure;
	}

	// Circle
	&.circle {

		&,
		strong,
		img:not(.icon) {
			border-radius: 50%;
		}

		&.badge:before {
			right: 0;
			bottom: 0;
		}
	
	}

	// Rounded
	&.rounded {
		&,
		strong,
		img {
			border-radius: 4px;
		}
	}

}

div.avatar-stackeds {

	display: inline-flex;

	em,
	div.avatar {
		width: 42px;
		height: 42px;
		border: 2px solid #ffffff;
	}
	em {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-left: -11px;
		font-size: 20px;
		line-height: 24px;
		font-weight: 400;
		color: $neutral-color-high-pure;
		border-radius: 50%;
		background: $neutral-color-high-dark;
	}

	div.avatar {

		display: none;

		&:nth-child(1),
		&:nth-child(2),
		&:nth-child(3) {
			display: flex;
		}
		&:not(:first-child){
			margin-left: -11px;
		}
		&:nth-child(1) {
			z-index: 10;
		}
		&:nth-child(2) {
			z-index: 9;
		}
		&:nth-child(3) {
			z-index: 8;
		}
	
	}

}