div.field-select {
    
    position: relative;
    width: 220px;
    padding-top: 10px;
    background: #ffffff;
    
    > span {

        position: relative;
        width: 100%;
        height: 56px;
        display: block;
        background: inherit;

        &:before,
        &:after {
            content: '';
            display: block;
            position: absolute;
        }

        &:before {
            width: 100%;
            height: 100%;
            background: #fff;
            border-radius: 4px;
            border: 1px solid $neutral-color-high-dark;
            transition: all .2s $transition-effect;
            z-index: 1;
        }

        &:after {
            top: 50%;
            right: 18px;
            width: 10px;
            height: 5px;
            border-top: 5px solid $neutral-color-low-medium;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            transition: all .4s $transition-effect;
            z-index: 5;
        }

    }

    em {
        display: flex;
        align-items: center;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 0 45px 0 15px;
        cursor: pointer;
        z-index: 30;
        opacity: 0;
        transition: opacity .2s linear;
    }

    label {
        @extend .noselect;
        position: absolute;
        top: 50%;
        left: 9px;
        width: auto;
        padding: 0 6px;
        transform: translateY( -50% );
        transition: all 300ms;
        background-color: inherit;
        cursor: default;
        color: $neutral-color-low-medium;
        background: inherit;
        z-index: 10;
    }

    select {
        position: absolute;
        display: none;
    }

    input {
        opacity: 0.1;
    }

    div.droplist {

        position: fixed;
        width: 100%;
        background: #FAFAFA;
        box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.16);
        border-radius: 0 0 4px 4px;
        transform: translateX(-200vw);
        opacity: 0;
        transition: 
            opacity .2s ease-in-out,
            transform 1ms linear .4s
        ;
        z-index: 999;

        ul {
            max-height: 304px;
            max-height: 204px;
            overflow-y: auto;
            overflow-x: hidden;
            outline: none;
        }

        li {

            padding: 6px 16px;
            font-size: 16px;
            line-height: 24px;
            color: #292929;
            cursor: pointer;
            transition: all .2s linear;
            outline: none;

            &.hover,
            &:focus {
                background: #EAF6FF;
            }

            &.active {
                background: #E0E0E0;
                cursor: default;
            }
        
        }
    
    }

    .prefix {
        padding: 0 0 0 14px;
        font-size: 16px;
        line-height: 24px;
        color: $neutral-color-low-medium;
    }

    .field-description {
        display: block;
        width: 100%;
        padding: 0 15px;
        font-size: 12px;
        line-height: 18px;
        margin-top: 2px;
        color: $neutral-color-low-medium;
    }

    &.drop-on-top div.droplist {
        box-shadow: 0px -8px 24px rgba(0, 0, 0, 0.16);
    }

    &.opened div.droplist {
        z-index: 9999;
        transition: .2s opacity ease-in-out;
        transform: translateX( 0 );
        opacity: 1;
    }

    &.opened,
    &.force-opened,
    &.filled,
    &.force-ffilled,
    &.with-prefix {        
        label {
            font-size: 0.8em;
            top: -2px;
            opacity: 1;    
            color: $neutral-color-low-medium;
        }
    
    }
    &.focus,
    &.force-focus {
        > span:before {
            border: 2px solid #0060A9;
        }
        label {
            color: $brand-color-primary-pure;
        }
    }
    &.focus:not(.error),
    &.force-focus:not(.error) {

        & > span:before { 
            border: 2px solid $brand-color-primary-pure;
        }
    
        input {
            height: 52px;
            line-height: 52px;
        }
    
    }
    &.filled,
    &.force-filled {
        em {
            opacity: 1;
        }
        input,
        textarea {
            color: $neutral-color-low-dark;
        }
    }

    &:not(.filled){

        color: $neutral-color-low-dark;

        ul li[rel=""],
        select option[value=""]{
            display: none;
        }
    
    }

    &.with-prefix {
        input,
        textarea {
            padding-left: 10px;
        }
    }

    &.disabled {

        input, 
        textarea, 
        label, 
        em.prefix {
            @extend .noselect;
            color: $neutral-color-high-dark;
        }

        span.suffix,
        button.suffix {
            opacity: 0.4;
            cursor: default;
        }
    
    }

    &.error {

        & > span:before { 
            border: 1px solid $feedback-color-negative-pure;
        }

        &.focus,
        &.force-focus,
        &.filled,
        &.force-ffilled {
            label {
                color: $feedback-color-negative-pure;
            }
        }
    
    }

}