// Font Family
$font-family-highlight: 'Lato';

// Font Weight
$font-weight-black: 900; // Black
$font-weight-bold: 700; // Bold
$font-weight-regular: 400; // Regular
$font-weight-light: 100; // Light

// Media sizes
$media-full-min: 1281px;
$media-inter-max: 1280px;
$media-inter-min: 769px;
$media-tablet-max: 768px;
$media-tablet-min: 481px;
$media-phone-max: 480px;

// Heights
$button-height-lg: 40px;
$button-height-md: 32px;
$button-height-sm: 24px;

// Font Size
$font-size-xxxs: 8px; // XXXS
$font-size-xxs: 10px; // XXS
$font-size-xs: 12px; // XS
$font-size-sm: 14px; // SM
$font-size-md: 16px; // MD
$font-size-lg: 20px; // LG
$font-size-xl: 24px; // XL
$font-size-xxl: 32px; // XXL
$font-size-xxxl: 40px; // XXXL
$font-size-display: 52px; // Display
$font-size-giant: 64px; // Giant

// Line Height
$line-height-default: 100%; // Default
$line-height-sm: 120%; // SM
$line-height-lg: 150%; // LG
$line-height-xl: 170%; // XL
$line-height-xxl: 200%; // XXL

// Border Radius
$border-radius-none: 0; // None
$border-radius-xs: 4px; // XS
$border-radius-SM: 8px; // SM
$border-radius-md: 16px; // MD
$border-radius-lg: 24px; // LG
$border-radius-pill: 500px; // Pill
$border-radius-circular: 50%; // Circular

// Border Width
$border-width-none: 0; // None
$border-width-hairline: 1px; // Hairline
$border-width-thin: 2px; // Thin
$border-width-thick: 4px; // Thick
$border-width-heavy: 8px; // Heavy

// Opacity Levels
$opacity-level-semiopaque: 0.8; // Semi Opaque
$opacity-level-intense: 0.64; // Intense
$opacity-level-medium: 0.32; // Medium
$opacity-level-light: 0.16; // Light
$opacity-level-semitransparent: 0.08; // Semi Transparent

// Shadow
$shadow-level-1: 0px 4px 8px rgba(0, 0, 0, 0.08); // Level 1
$shadow-level-2: 0px 8px 24px rgba(0, 0, 0, 0.16); // Level 2
$shadow-level-3: 0px 16px 32px rgba(0, 0, 0, 0.16); // Level 3
$shadow-level-4: 0px 16px 48px rgba(0, 0, 0, 0.24); // Level 4
$shadow-level-5: 0px 1px 2px rgba(0, 0, 0, 0.32); // Level 5
$shadow-level-6: 0px 2px 4px rgba(0, 0, 0, 0.4); // Level 6

// Spacing
$spacing-quarck: 4px; // Quarck
$spacing-nano: 8px; // Nano
$spacing-xxxs: 16px; // XXXS
$spacing-xxs: 24px; // XXS
$spacing-xs: 32px; // XS
$spacing-sm: 40px; // SM
$spacing-md: 48px; // MD
$spacing-lg: 56px; // LG
$spacing-xl: 64px; // XL
$spacing-xxl: 80px; // XXL
$spacing-xxxl: 120px; // XXXL
$spacing-huge: 160px; // Huge
$spacing-giant: 200px; // Giant

// Spacing Inset
$spacing_inset-quarck: 4px; // Quarck
$spacing_inset-nano: 8px; // Nano
$spacing_inset-xs: 16px; // XS
$spacing_inset-sm: 24px; // SM
$spacing_inset-md: 32px; // MD
$spacing_inset-lg: 40px; // LG

// Transition
$transition-effect: ease-in-out;
$transition-fast: .4s;
$transition-medium: .6s;
$transition-low: 1s;

// Colors

	// Error
	$error-color: #F50041;

	// 	Brand 
	$brand-color-primary-pure: #0060A9; // Primary Pure
	$brand-color-primary-light: #EAF6FF; // Primary Light
	$brand-color-primary-medium: #66A0CB; // Primary Medium
	$brand-color-primary-dark: #001F37; // Primary Dark
	$brand-color-secondary-pure: #FFF200; // Secondary Pure
	$brand-color-secondary-light: #FFFCCC; // Secondary Light
	$brand-color-secondary-medium: #FFFA99; // Secondary Medium
	$brand-color-secondary-dark: #9C9400; // Secondary Dark

	// 	Neutral 
	$neutral-color-low-pure: #000000; // Low Pure
	$neutral-color-low-light: #A3A3A3; // Low Light
	$neutral-color-low-medium: #666666; // Low Medium
	$neutral-color-low-dark: #292929; // Low Dark
	$neutral-color-high-pure: #FFFFFF; // High Pure
	$neutral-color-high-light: #FAFAFA; // High Light
	$neutral-color-high-medium: #E0E0E0; // High Medium
	$neutral-color-high-dark: #CCCCCC; // High Dark

	//  Feedback
		
		//    Positive
		$feedback-color-positive-pure: #3AA76D; // Positive Pure
		$feedback-color-positive-light: #ECFFF7; // Positive Light
		$feedback-color-positive-medium: #7AD0A3; // Positive Medium
		$feedback-color-positive-dark: #10462D; // Positive Dark
		
		//    Warning
		$feedback-color-warning-pure: #FFC043; // Warning Pure
		$feedback-color-warning-light: #FFF2D9; // Warning Light
		$feedback-color-warning-medium: #FFDB94; // Warning Medium
		$feedback-color-warning-dark: #674D1B; // Warning Dark
		
		//    Negative
		$feedback-color-negative-pure: #D44333; // Negative Pure
		$feedback-color-negative-light: #FFF7F5; // Negative Light
		$feedback-color-negative-medium: #F27B6C; // Negative Medium
		$feedback-color-negative-dark: #5A0A00; // Negative Dark
		
		//    Highlight
		$feedback-color-highlight-pure: #F673AD; // Highlight Pure
		$feedback-color-highlight-light: #FDD9E9; // Highlight Light
		$feedback-color-highlight-medium: #900945; // Highlight Medium
		$feedback-color-Highlight-dark: #430420; // Highlight Dark

	//  Alternative

		//   Purple
		$alternative-color-purple-pure: #7356BF; // Purple Pure
		$alternative-color-purple-light: #E3DDF2; // Purple Light
		$alternative-color-purple-medium: #9C83DB; // Purple Medium
		$alternative-color-purple-dark: #2E224C; // Purple Dark
	
		//   Orange
		$alternative-color-orange-pure: #ED6E33; // Orange Pure
		$alternative-color-orange-light: #FFE1D6; // Orange Light
		$alternative-color-orange-medium: #F49E76; // Orange Medium
		$alternative-color-orange-dark: #672A16; // Orange Dark
	
		//   Navy
		$alternative-color-navy-pure: #0E1FC1; // Navy Pure
		$alternative-color-navy-light: #EBEDFA; // Navy Light
		$alternative-color-navy-medium: #6F7BEF; // Navy Medium
		$alternative-color-navy-dark: #050C4D; // Navy Dark
	
		//   Brown
		$alternative-color-brown-pure: #99644C; // Brown Pure
		$alternative-color-brown-light: #F6F0EA; // Brown Light
		$alternative-color-brown-medium: #BD9482; // Brown Medium
		$alternative-color-brown-dark: #3D281E; // Brown Dark







