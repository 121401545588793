button,
a.button {

	display: table;
	position: relative;
	height: $button-height-lg;
	padding: 0 24px;
	line-height: calc( $button-height-lg - 2px );
	border-radius: 4px;
	box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.32);
	border: 1px solid transparent;
	text-align: center;
	text-decoration: none;
	transition: 
		color .3s ease-in-out,
		background-color .3s ease-in-out
	;

	&:not(:disabled){
		cursor: pointer;
	}

	strong {
		display: block;
		font-weight: normal;
		font-size: 16px;
	}

	&.left:before,
	&.right:after,
	&.square:before {
		@extend .arrow-button;
		content: '';
		display: block;
		position: absolute;
		top: 50%;
		width: 12px;
		height: 12px;
		transition: opacity .3s ease-in-out;
		background-size: contain;
		background-repeat: no-repeat;
	}
	&.left {

		padding-left: 56px;

		&:before {
			left: 30px;
			transform: scaleX(-1) translateY(-50%);
		}

	}
	&.right {

		padding-right: 56px;

		&:after {
			right: 30px;
			transform: translateY(-50%);
		}

	}

	&.square {

		width: 40px;
		padding: 0 !important;
		
		strong {
			overflow: hidden;
			text-indent: -9999px;
			color: transparent;
		}

		&:before,
		strong:before {
			left: 50% !important;
			transform: translate(-50%,-50%) !important;
		}

		&.top {
			&:before,
			strong:before {
				transform: translate(-50%,-50%) rotate(-90deg) !important;
			}
		}
		&.down {
			&:before,
			strong:before {
				transform: translate(-50%,-50%) rotate(90deg) !important;
			}
		}
		&.left {
			&:before,
			strong:before {
				transform: translate(-50%,-50%) scale(-1) !important;
			}
		}
	
	}

	&.primary:not(:disabled) {

		background: $brand-color-primary-pure;
		color: $neutral-color-high-pure;

		&:not(:disabled):not(.lock):hover,
		&:not(:disabled):not(.lock).hover {
			background-color: $brand-color-primary-medium;
		}
	
	}

	&.secondary:not(:disabled) {

		background: $brand-color-primary-dark;
		color: $neutral-color-high-pure;

		&.left strong:before,
		&.right strong:after,
		&.square strong:before {
			content: '';
			display: block;
			position: absolute;
			top: 50%;
			width: 12px;
			height: 12px;
			opacity: 0;
			transition: opacity .3s ease-in-out;
			background-size: contain;
			background-repeat: no-repeat;
			@extend .arrow-button-dark;
		}
		&.left strong:before {
			left: 30px;
			transform: scaleX(-1) translateY(-50%);
		}
		&.right strong:after {
			right: 30px;
			transform: translateY(-50%);
		}

		&:not(:disabled):not(.lock):hover,
		&:not(:disabled):not(.lock).hover {

			background-color: $neutral-color-high-pure;
			color: $brand-color-primary-dark;

			&:before,
			&:after {
				opacity: 0;
			}
			strong {
				&:before,
				&:after {
					opacity: 1;
				}
			}
		
		}
	
	}

	&:disabled,
	.disabled {

		background-color: $neutral-color-high-medium;
		color: $neutral-color-low-light;
		box-shadow: none;

		&:after,
		&:before {
			@extend .arrow-button-grey;
		}
	
	}

	&.outlined,
	&.text {

		box-shadow: none;
		transition: opacity .3s ease-in-out;

		&:not(:disabled):not(.lock):hover,
		&:not(:disabled):not(.lock).hover {
			opacity: 0.55;
		}

	}

	&.outlined {

		&.primary:not(:disabled) {

			background: transparent;
			border-color: $brand-color-primary-pure;
			color: $brand-color-primary-pure;

			&:before,
			&:after {
				@extend .arrow-button-blue;
			}

			&:not(:disabled):not(.lock):hover,
			&:not(:disabled):not(.lock).hover {
				background-color: transparent;
			}

		}

		&.secondary:not(:disabled) {

			color: $brand-color-primary-dark;
			border-color: $brand-color-primary-dark;
			background: transparent;

			&:before,
			&:after {
				@extend .arrow-button-dark;
			}

		}
	
		&:disabled {

			background-color: transparent;
			color: $neutral-color-low-light;
			border-color: $neutral-color-low-light;
			opacity: 0.55;

			&:after,
			&:before {
				@extend .arrow-button-grey;
			}
		
		}

	}

	&.text {

		//padding: 0;
		border-color: transparent !important;
		background-color: transparent !important;

		/*
		&.left {

			padding-left: 26px;

			&:before,
			strong:before {
				left: 0;
			}

		}
		&.right {

			padding-right: 26px;

			&:after,
			strong:after {
				right: 0;
			}

		}
		*/

		&.primary:not(:disabled) {

			background: transparent;
			color: $brand-color-primary-pure;

			&:before,
			&:after {
				@extend .arrow-button-blue;
			}

			&:not(:disabled):not(.lock):hover,
			&:not(:disabled):not(.lock).hover {
				background-color: transparent;
			}

		}

		&.secondary {

			/*
			&.left strong:before {
				left: 0;
			}
			&.right strong:after {
				right: 0;
			}
			*/

			&:not(:disabled) {

				color: $brand-color-primary-dark;
				background: transparent;

				&:before,
				&:after {
					@extend .arrow-button-dark;
				}

			}

		}
	
		&:disabled {

			background-color: transparent;
			color: $neutral-color-low-light;
			opacity: 0.55;

			&:after,
			&:before {
				@extend .arrow-button-grey;
			}
		
		}

	}

	&.size {

		&--2,
		&--3 {

			strong {
				font-size: 14px;
			}

			&.left:before,
			&.right:after {
				width: 10px;
				height: 10px;
			}
		
			&.secondary:not(:disabled) {

				&.left strong:before,
				&.right strong:after {
					width: 10px;
					height: 10px;
				}
			
			}
			
			/*
			&.text {

				padding: 0;

				&.left {

					padding-left: 22px;

					&:before,
					strong:before {
						left: 0;
					}

				}

				&.right {

					padding-right: 22px;

					&:after,
					strong:after {
						right: 0;
					}

				}
			
			}
			*/

		}

		&--2 {

			height: $button-height-md;
			padding: 0 16px;
			line-height: calc( $button-height-md - 2px );

			&.left {

				padding-left: 41px;

				&:before,
				strong:before {
					left: 20px;
				}

			}
			&.right {

				padding-right: 41px;

				&:after,
				strong:after {
					right: 20px;
				}

			}
			&.square {
				width: 32px;
				padding: 0;
			}
		
			&.secondary:not(:disabled) {

				&.left strong:before {
					left: 20px;
				}
				&.right strong:after {
					right: 20px;
				}

				/*
				&.text {
					&.left strong:before {
						left: 0;
					}
					&.right strong:after {
						right: 0;
					}
				}
				*/
			
			}

		}

		&--3 {

			height: $button-height-sm;
			padding: 0 8px;
			line-height: calc( $button-height-sm - 2px );

			&.left {

				padding-left: 28px;

				&:before {
					left: 8px;
				}

			}
			&.right {

				padding-right: 28px;

				&:after {
					right: 8px;
				}

			}
			&.square {
				width: 24px;
				padding: 0;
			}
		
			&.secondary:not(:disabled) {

				&.left strong:before {
					left: 8px;
				}
				&.right strong:after {
					right: 8px;
				}

				/*
				&.text {
					&.left strong:before {
						left: 0;
					}
					&.right strong:after {
						right: 0;
					}
				}
				*/
			
			}

		}

	}

}