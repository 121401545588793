@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,400;0,700;0,900;1,100;1,400;1,700;1,900&display=swap');

// Structure
	
	// Variables
	@import 'structure/variables';
	// Images
	@import 'structure/images';
	// Common
	@import 'structure/common';

// Components

	// Heading
	@import 'components/heading';
	// Button
	@import 'components/button';
	// Buttons group
	@import 'components/buttons-group';
	// Chip
	@import 'components/chip';
	// Slider
	@import 'components/nouislider';
	@import 'components/slider';
	// Input	
	@import 'components/input';
	// Input	
	@import 'components/select';
	// Checkbox
	@import 'components/checkbox';
	// States
	@import 'components/states';
	// Alerta
	@import 'components/alerta';
	// Cards
	@import 'components/card';
	// Menu
	@import 'components/menu';
	// Avatar
	@import 'components/avatar';
	// Icons
	@import 'components/icon';
	@import 'components/icons';
	// Icon
	@import 'components/modal';
