*, *:before, *:after {
    font-family: inherit;
    color: inherit;
}

body {
    font-family: 'Lato';
}

.noselect {
  	-webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
   	-moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}