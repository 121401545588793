.icon {
   &--0-plus {
       background-image: url('../icons/0-plus.svg');
   }
   &--10-plus {
       background-image: url('../icons/10-plus.svg');
   }
   &--12-plus {
       background-image: url('../icons/12-plus.svg');
   }
   &--13-plus {
       background-image: url('../icons/13-plus.svg');
   }
   &--16-plus {
       background-image: url('../icons/16-plus.svg');
   }
   &--17-plus {
       background-image: url('../icons/17-plus.svg');
   }
   &--18-plus {
       background-image: url('../icons/18-plus.svg');
   }
   &--21-plus {
       background-image: url('../icons/21-plus.svg');
   }
   &--3-plus {
       background-image: url('../icons/3-plus.svg');
   }
   &--500px {
       background-image: url('../icons/500px.svg');
   }
   &--6-plus {
       background-image: url('../icons/6-plus.svg');
   }
   &--abacus {
       background-image: url('../icons/abacus.svg');
   }
   &--accessible-icon-alt {
       background-image: url('../icons/accessible-icon-alt.svg');
   }
   &--adjust-alt {
       background-image: url('../icons/adjust-alt.svg');
   }
   &--adjust-circle {
       background-image: url('../icons/adjust-circle.svg');
   }
   &--adjust-half {
       background-image: url('../icons/adjust-half.svg');
   }
   &--adjust {
       background-image: url('../icons/adjust.svg');
   }
   &--adobe-alt {
       background-image: url('../icons/adobe-alt.svg');
   }
   &--adobe {
       background-image: url('../icons/adobe.svg');
   }
   &--airplay {
       background-image: url('../icons/airplay.svg');
   }
   &--align-alt {
       background-image: url('../icons/align-alt.svg');
   }
   &--align-center-alt {
       background-image: url('../icons/align-center-alt.svg');
   }
   &--align-center-h {
       background-image: url('../icons/align-center-h.svg');
   }
   &--align-center-justify {
       background-image: url('../icons/align-center-justify.svg');
   }
   &--align-center-v {
       background-image: url('../icons/align-center-v.svg');
   }
   &--align-center {
       background-image: url('../icons/align-center.svg');
   }
   &--align-justify {
       background-image: url('../icons/align-justify.svg');
   }
   &--align-left-justify {
       background-image: url('../icons/align-left-justify.svg');
   }
   &--align-left {
       background-image: url('../icons/align-left.svg');
   }
   &--align-letter-right {
       background-image: url('../icons/align-letter-right.svg');
   }
   &--align-right-justify {
       background-image: url('../icons/align-right-justify.svg');
   }
   &--align-right {
       background-image: url('../icons/align-right.svg');
   }
   &--align {
       background-image: url('../icons/align.svg');
   }
   &--amazon {
       background-image: url('../icons/amazon.svg');
   }
   &--ambulance {
       background-image: url('../icons/ambulance.svg');
   }
   &--analysis {
       background-image: url('../icons/analysis.svg');
   }
   &--analytics {
       background-image: url('../icons/analytics.svg');
   }
   &--anchor {
       background-image: url('../icons/anchor.svg');
   }
   &--android-alt {
       background-image: url('../icons/android-alt.svg');
   }
   &--android-phone-slash {
       background-image: url('../icons/android-phone-slash.svg');
   }
   &--android {
       background-image: url('../icons/android.svg');
   }
   &--angle-double-down {
       background-image: url('../icons/angle-double-down.svg');
   }
   &--angle-double-left {
       background-image: url('../icons/angle-double-left.svg');
   }
   &--angle-double-right {
       background-image: url('../icons/angle-double-right.svg');
   }
   &--angle-double-up {
       background-image: url('../icons/angle-double-up.svg');
   }
   &--angle-down {
       background-image: url('../icons/angle-down.svg');
   }
   &--angle-left-b {
       background-image: url('../icons/angle-left-b.svg');
   }
   &--angle-left {
       background-image: url('../icons/angle-left.svg');
   }
   &--angle-right-b {
       background-image: url('../icons/angle-right-b.svg');
   }
   &--angle-right {
       background-image: url('../icons/angle-right.svg');
   }
   &--angle-up {
       background-image: url('../icons/angle-up.svg');
   }
   &--angry {
       background-image: url('../icons/angry.svg');
   }
   &--ankh {
       background-image: url('../icons/ankh.svg');
   }
   &--annoyed-alt {
       background-image: url('../icons/annoyed-alt.svg');
   }
   &--annoyed {
       background-image: url('../icons/annoyed.svg');
   }
   &--apple-alt {
       background-image: url('../icons/apple-alt.svg');
   }
   &--apple {
       background-image: url('../icons/apple.svg');
   }
   &--apps {
       background-image: url('../icons/apps.svg');
   }
   &--archive-alt {
       background-image: url('../icons/archive-alt.svg');
   }
   &--archive {
       background-image: url('../icons/archive.svg');
   }
   &--archway {
       background-image: url('../icons/archway.svg');
   }
   &--arrow-break {
       background-image: url('../icons/arrow-break.svg');
   }
   &--arrow-circle-down {
       background-image: url('../icons/arrow-circle-down.svg');
   }
   &--arrow-circle-left {
       background-image: url('../icons/arrow-circle-left.svg');
   }
   &--arrow-circle-right {
       background-image: url('../icons/arrow-circle-right.svg');
   }
   &--arrow-circle-up {
       background-image: url('../icons/arrow-circle-up.svg');
   }
   &--arrow-compress-h {
       background-image: url('../icons/arrow-compress-h.svg');
   }
   &--arrow-down-left {
       background-image: url('../icons/arrow-down-left.svg');
   }
   &--arrow-down-right {
       background-image: url('../icons/arrow-down-right.svg');
   }
   &--arrow-down {
       background-image: url('../icons/arrow-down.svg');
   }
   &--arrow-from-right {
       background-image: url('../icons/arrow-from-right.svg');
   }
   &--arrow-from-top {
       background-image: url('../icons/arrow-from-top.svg');
   }
   &--arrow-growth {
       background-image: url('../icons/arrow-growth.svg');
   }
   &--arrow-left {
       background-image: url('../icons/arrow-left.svg');
   }
   &--arrow-random {
       background-image: url('../icons/arrow-random.svg');
   }
   &--arrow-resize-diagonal {
       background-image: url('../icons/arrow-resize-diagonal.svg');
   }
   &--arrow-right {
       background-image: url('../icons/arrow-right.svg');
   }
   &--arrow-to-bottom {
       background-image: url('../icons/arrow-to-bottom.svg');
   }
   &--arrow-to-right {
       background-image: url('../icons/arrow-to-right.svg');
   }
   &--arrow-up-left {
       background-image: url('../icons/arrow-up-left.svg');
   }
   &--arrow-up-right {
       background-image: url('../icons/arrow-up-right.svg');
   }
   &--arrow-up {
       background-image: url('../icons/arrow-up.svg');
   }
   &--arrow {
       background-image: url('../icons/arrow.svg');
   }
   &--arrows-h-alt {
       background-image: url('../icons/arrows-h-alt.svg');
   }
   &--arrows-h {
       background-image: url('../icons/arrows-h.svg');
   }
   &--arrows-left-down {
       background-image: url('../icons/arrows-left-down.svg');
   }
   &--arrows-maximize {
       background-image: url('../icons/arrows-maximize.svg');
   }
   &--arrows-merge {
       background-image: url('../icons/arrows-merge.svg');
   }
   &--arrows-resize-h {
       background-image: url('../icons/arrows-resize-h.svg');
   }
   &--arrows-resize-v {
       background-image: url('../icons/arrows-resize-v.svg');
   }
   &--arrows-resize {
       background-image: url('../icons/arrows-resize.svg');
   }
   &--arrows-right-down {
       background-image: url('../icons/arrows-right-down.svg');
   }
   &--arrows-shrink-h {
       background-image: url('../icons/arrows-shrink-h.svg');
   }
   &--arrows-shrink-v {
       background-image: url('../icons/arrows-shrink-v.svg');
   }
   &--arrows-up-right {
       background-image: url('../icons/arrows-up-right.svg');
   }
   &--arrows-v-alt {
       background-image: url('../icons/arrows-v-alt.svg');
   }
   &--arrows-v {
       background-image: url('../icons/arrows-v.svg');
   }
   &--assistive-listening-systems {
       background-image: url('../icons/assistive-listening-systems.svg');
   }
   &--asterisk {
       background-image: url('../icons/asterisk.svg');
   }
   &--at {
       background-image: url('../icons/at.svg');
   }
   &--atom {
       background-image: url('../icons/atom.svg');
   }
   &--auto-flash {
       background-image: url('../icons/auto-flash.svg');
   }
   &--award-alt {
       background-image: url('../icons/award-alt.svg');
   }
   &--award {
       background-image: url('../icons/award.svg');
   }
   &--baby-carriage {
       background-image: url('../icons/baby-carriage.svg');
   }
   &--backpack {
       background-image: url('../icons/backpack.svg');
   }
   &--backspace {
       background-image: url('../icons/backspace.svg');
   }
   &--backward {
       background-image: url('../icons/backward.svg');
   }
   &--bag-alt {
       background-image: url('../icons/bag-alt.svg');
   }
   &--bag-slash {
       background-image: url('../icons/bag-slash.svg');
   }
   &--bag {
       background-image: url('../icons/bag.svg');
   }
   &--balance-scale {
       background-image: url('../icons/balance-scale.svg');
   }
   &--ban {
       background-image: url('../icons/ban.svg');
   }
   &--band-aid {
       background-image: url('../icons/band-aid.svg');
   }
   &--bars {
       background-image: url('../icons/bars.svg');
   }
   &--baseball-ball {
       background-image: url('../icons/baseball-ball.svg');
   }
   &--basketball-hoop {
       background-image: url('../icons/basketball-hoop.svg');
   }
   &--basketball {
       background-image: url('../icons/basketball.svg');
   }
   &--bath {
       background-image: url('../icons/bath.svg');
   }
   &--battery-bolt {
       background-image: url('../icons/battery-bolt.svg');
   }
   &--battery-empty {
       background-image: url('../icons/battery-empty.svg');
   }
   &--bed-double {
       background-image: url('../icons/bed-double.svg');
   }
   &--bed {
       background-image: url('../icons/bed.svg');
   }
   &--behance-alt {
       background-image: url('../icons/behance-alt.svg');
   }
   &--behance {
       background-image: url('../icons/behance.svg');
   }
   &--bell-school {
       background-image: url('../icons/bell-school.svg');
   }
   &--bell-slash {
       background-image: url('../icons/bell-slash.svg');
   }
   &--bell {
       background-image: url('../icons/bell.svg');
   }
   &--bill {
       background-image: url('../icons/bill.svg');
   }
   &--bing {
       background-image: url('../icons/bing.svg');
   }
   &--bitcoin-alt {
       background-image: url('../icons/bitcoin-alt.svg');
   }
   &--bitcoin-circle {
       background-image: url('../icons/bitcoin-circle.svg');
   }
   &--bitcoin-sign {
       background-image: url('../icons/bitcoin-sign.svg');
   }
   &--bitcoin {
       background-image: url('../icons/bitcoin.svg');
   }
   &--black-berry {
       background-image: url('../icons/black-berry.svg');
   }
   &--blogger-alt {
       background-image: url('../icons/blogger-alt.svg');
   }
   &--blogger {
       background-image: url('../icons/blogger.svg');
   }
   &--bluetooth-b {
       background-image: url('../icons/bluetooth-b.svg');
   }
   &--bold {
       background-image: url('../icons/bold.svg');
   }
   &--bolt-alt {
       background-image: url('../icons/bolt-alt.svg');
   }
   &--bolt-slash {
       background-image: url('../icons/bolt-slash.svg');
   }
   &--bolt {
       background-image: url('../icons/bolt.svg');
   }
   &--book-alt {
       background-image: url('../icons/book-alt.svg');
   }
   &--book-medical {
       background-image: url('../icons/book-medical.svg');
   }
   &--book-open {
       background-image: url('../icons/book-open.svg');
   }
   &--book-reader {
       background-image: url('../icons/book-reader.svg');
   }
   &--book {
       background-image: url('../icons/book.svg');
   }
   &--bookmark-full {
       background-image: url('../icons/bookmark-full.svg');
   }
   &--bookmark {
       background-image: url('../icons/bookmark.svg');
   }
   &--books {
       background-image: url('../icons/books.svg');
   }
   &--boombox {
       background-image: url('../icons/boombox.svg');
   }
   &--border-alt {
       background-image: url('../icons/border-alt.svg');
   }
   &--border-bottom {
       background-image: url('../icons/border-bottom.svg');
   }
   &--border-clear {
       background-image: url('../icons/border-clear.svg');
   }
   &--border-horizontal {
       background-image: url('../icons/border-horizontal.svg');
   }
   &--border-inner {
       background-image: url('../icons/border-inner.svg');
   }
   &--border-left {
       background-image: url('../icons/border-left.svg');
   }
   &--border-out {
       background-image: url('../icons/border-out.svg');
   }
   &--border-right {
       background-image: url('../icons/border-right.svg');
   }
   &--border-top {
       background-image: url('../icons/border-top.svg');
   }
   &--border-vertical {
       background-image: url('../icons/border-vertical.svg');
   }
   &--bowling-ball {
       background-image: url('../icons/bowling-ball.svg');
   }
   &--box {
       background-image: url('../icons/box.svg');
   }
   &--brackets-curly {
       background-image: url('../icons/brackets-curly.svg');
   }
   &--brain {
       background-image: url('../icons/brain.svg');
   }
   &--briefcase-alt {
       background-image: url('../icons/briefcase-alt.svg');
   }
   &--briefcase {
       background-image: url('../icons/briefcase.svg');
   }
   &--bright {
       background-image: url('../icons/bright.svg');
   }
   &--brightness-empty {
       background-image: url('../icons/brightness-empty.svg');
   }
   &--brightness-half {
       background-image: url('../icons/brightness-half.svg');
   }
   &--brightness-low {
       background-image: url('../icons/brightness-low.svg');
   }
   &--brightness-minus {
       background-image: url('../icons/brightness-minus.svg');
   }
   &--brightness-plus {
       background-image: url('../icons/brightness-plus.svg');
   }
   &--brightness {
       background-image: url('../icons/brightness.svg');
   }
   &--bring-bottom {
       background-image: url('../icons/bring-bottom.svg');
   }
   &--bring-front {
       background-image: url('../icons/bring-front.svg');
   }
   &--browser {
       background-image: url('../icons/browser.svg');
   }
   &--brush-alt {
       background-image: url('../icons/brush-alt.svg');
   }
   &--bug {
       background-image: url('../icons/bug.svg');
   }
   &--building {
       background-image: url('../icons/building.svg');
   }
   &--bullseye {
       background-image: url('../icons/bullseye.svg');
   }
   &--bus-alt {
       background-image: url('../icons/bus-alt.svg');
   }
   &--bus-school {
       background-image: url('../icons/bus-school.svg');
   }
   &--bus {
       background-image: url('../icons/bus.svg');
   }
   &--calculator-alt {
       background-image: url('../icons/calculator-alt.svg');
   }
   &--calculator {
       background-image: url('../icons/calculator.svg');
   }
   &--calendar-alt {
       background-image: url('../icons/calendar-alt.svg');
   }
   &--calendar-slash {
       background-image: url('../icons/calendar-slash.svg');
   }
   &--calender {
       background-image: url('../icons/calender.svg');
   }
   &--calling {
       background-image: url('../icons/calling.svg');
   }
   &--camera-change {
       background-image: url('../icons/camera-change.svg');
   }
   &--camera-plus {
       background-image: url('../icons/camera-plus.svg');
   }
   &--camera-slash {
       background-image: url('../icons/camera-slash.svg');
   }
   &--camera {
       background-image: url('../icons/camera.svg');
   }
   &--cancel {
       background-image: url('../icons/cancel.svg');
   }
   &--capsule {
       background-image: url('../icons/capsule.svg');
   }
   &--capture {
       background-image: url('../icons/capture.svg');
   }
   &--car-sideview {
       background-image: url('../icons/car-sideview.svg');
   }
   &--car-slash {
       background-image: url('../icons/car-slash.svg');
   }
   &--car-wash {
       background-image: url('../icons/car-wash.svg');
   }
   &--car {
       background-image: url('../icons/car.svg');
   }
   &--card-atm {
       background-image: url('../icons/card-atm.svg');
   }
   &--caret-right {
       background-image: url('../icons/caret-right.svg');
   }
   &--cell {
       background-image: url('../icons/cell.svg');
   }
   &--celsius {
       background-image: url('../icons/celsius.svg');
   }
   &--channel-add {
       background-image: url('../icons/channel-add.svg');
   }
   &--channel {
       background-image: url('../icons/channel.svg');
   }
   &--chart-bar-alt {
       background-image: url('../icons/chart-bar-alt.svg');
   }
   &--chart-bar {
       background-image: url('../icons/chart-bar.svg');
   }
   &--chart-down {
       background-image: url('../icons/chart-down.svg');
   }
   &--chart-growth-alt {
       background-image: url('../icons/chart-growth-alt.svg');
   }
   &--chart-growth {
       background-image: url('../icons/chart-growth.svg');
   }
   &--chart-line {
       background-image: url('../icons/chart-line.svg');
   }
   &--chart-pie-alt {
       background-image: url('../icons/chart-pie-alt.svg');
   }
   &--chart-pie {
       background-image: url('../icons/chart-pie.svg');
   }
   &--chart {
       background-image: url('../icons/chart.svg');
   }
   &--chat-bubble-user {
       background-image: url('../icons/chat-bubble-user.svg');
   }
   &--chat-info {
       background-image: url('../icons/chat-info.svg');
   }
   &--chat {
       background-image: url('../icons/chat.svg');
   }
   &--check-circle {
       background-image: url('../icons/check-circle.svg');
   }
   &--check-square {
       background-image: url('../icons/check-square.svg');
   }
   &--check {
       background-image: url('../icons/check.svg');
   }
   &--circle-layer {
       background-image: url('../icons/circle-layer.svg');
   }
   &--circle {
       background-image: url('../icons/circle.svg');
   }
   &--circuit {
       background-image: url('../icons/circuit.svg');
   }
   &--clapper-board {
       background-image: url('../icons/clapper-board.svg');
   }
   &--clinic-medical {
       background-image: url('../icons/clinic-medical.svg');
   }
   &--clipboard-alt {
       background-image: url('../icons/clipboard-alt.svg');
   }
   &--clipboard-blank {
       background-image: url('../icons/clipboard-blank.svg');
   }
   &--clipboard-notes {
       background-image: url('../icons/clipboard-notes.svg');
   }
   &--clipboard {
       background-image: url('../icons/clipboard.svg');
   }
   &--clock-eight {
       background-image: url('../icons/clock-eight.svg');
   }
   &--clock-five {
       background-image: url('../icons/clock-five.svg');
   }
   &--clock-nine {
       background-image: url('../icons/clock-nine.svg');
   }
   &--clock-seven {
       background-image: url('../icons/clock-seven.svg');
   }
   &--clock-ten {
       background-image: url('../icons/clock-ten.svg');
   }
   &--clock-three {
       background-image: url('../icons/clock-three.svg');
   }
   &--clock-two {
       background-image: url('../icons/clock-two.svg');
   }
   &--clock {
       background-image: url('../icons/clock.svg');
   }
   &--closed-captioning-slash {
       background-image: url('../icons/closed-captioning-slash.svg');
   }
   &--closed-captioning {
       background-image: url('../icons/closed-captioning.svg');
   }
   &--cloud-block {
       background-image: url('../icons/cloud-block.svg');
   }
   &--cloud-bookmark {
       background-image: url('../icons/cloud-bookmark.svg');
   }
   &--cloud-check {
       background-image: url('../icons/cloud-check.svg');
   }
   &--cloud-computing {
       background-image: url('../icons/cloud-computing.svg');
   }
   &--cloud-data-connection {
       background-image: url('../icons/cloud-data-connection.svg');
   }
   &--cloud-database-tree {
       background-image: url('../icons/cloud-database-tree.svg');
   }
   &--cloud-download {
       background-image: url('../icons/cloud-download.svg');
   }
   &--cloud-drizzle {
       background-image: url('../icons/cloud-drizzle.svg');
   }
   &--cloud-exclamation {
       background-image: url('../icons/cloud-exclamation.svg');
   }
   &--cloud-hail {
       background-image: url('../icons/cloud-hail.svg');
   }
   &--cloud-heart {
       background-image: url('../icons/cloud-heart.svg');
   }
   &--cloud-info {
       background-image: url('../icons/cloud-info.svg');
   }
   &--cloud-lock {
       background-image: url('../icons/cloud-lock.svg');
   }
   &--cloud-meatball {
       background-image: url('../icons/cloud-meatball.svg');
   }
   &--cloud-moon-hail {
       background-image: url('../icons/cloud-moon-hail.svg');
   }
   &--cloud-moon-meatball {
       background-image: url('../icons/cloud-moon-meatball.svg');
   }
   &--cloud-moon-rain {
       background-image: url('../icons/cloud-moon-rain.svg');
   }
   &--cloud-moon-showers {
       background-image: url('../icons/cloud-moon-showers.svg');
   }
   &--cloud-moon {
       background-image: url('../icons/cloud-moon.svg');
   }
   &--cloud-question {
       background-image: url('../icons/cloud-question.svg');
   }
   &--cloud-rain-sun {
       background-image: url('../icons/cloud-rain-sun.svg');
   }
   &--cloud-rain {
       background-image: url('../icons/cloud-rain.svg');
   }
   &--cloud-redo {
       background-image: url('../icons/cloud-redo.svg');
   }
   &--cloud-share {
       background-image: url('../icons/cloud-share.svg');
   }
   &--cloud-shield {
       background-image: url('../icons/cloud-shield.svg');
   }
   &--cloud-showers-alt {
       background-image: url('../icons/cloud-showers-alt.svg');
   }
   &--cloud-showers-heavy {
       background-image: url('../icons/cloud-showers-heavy.svg');
   }
   &--cloud-showers {
       background-image: url('../icons/cloud-showers.svg');
   }
   &--cloud-slash {
       background-image: url('../icons/cloud-slash.svg');
   }
   &--cloud-sun-hail {
       background-image: url('../icons/cloud-sun-hail.svg');
   }
   &--cloud-sun-meatball {
       background-image: url('../icons/cloud-sun-meatball.svg');
   }
   &--cloud-sun-rain-alt {
       background-image: url('../icons/cloud-sun-rain-alt.svg');
   }
   &--cloud-sun-rain {
       background-image: url('../icons/cloud-sun-rain.svg');
   }
   &--cloud-sun-tear {
       background-image: url('../icons/cloud-sun-tear.svg');
   }
   &--cloud-sun {
       background-image: url('../icons/cloud-sun.svg');
   }
   &--cloud-times {
       background-image: url('../icons/cloud-times.svg');
   }
   &--cloud-unlock {
       background-image: url('../icons/cloud-unlock.svg');
   }
   &--cloud-upload {
       background-image: url('../icons/cloud-upload.svg');
   }
   &--cloud-wifi {
       background-image: url('../icons/cloud-wifi.svg');
   }
   &--cloud-wind {
       background-image: url('../icons/cloud-wind.svg');
   }
   &--cloud {
       background-image: url('../icons/cloud.svg');
   }
   &--clouds {
       background-image: url('../icons/clouds.svg');
   }
   &--club {
       background-image: url('../icons/club.svg');
   }
   &--code-branch {
       background-image: url('../icons/code-branch.svg');
   }
   &--coffee {
       background-image: url('../icons/coffee.svg');
   }
   &--cog {
       background-image: url('../icons/cog.svg');
   }
   &--coins {
       background-image: url('../icons/coins.svg');
   }
   &--columns {
       background-image: url('../icons/columns.svg');
   }
   &--comment-add {
       background-image: url('../icons/comment-add.svg');
   }
   &--comment-alt-block {
       background-image: url('../icons/comment-alt-block.svg');
   }
   &--comment-alt-chart-lines {
       background-image: url('../icons/comment-alt-chart-lines.svg');
   }
   &--comment-alt-check {
       background-image: url('../icons/comment-alt-check.svg');
   }
   &--comment-alt-dots {
       background-image: url('../icons/comment-alt-dots.svg');
   }
   &--comment-alt-download {
       background-image: url('../icons/comment-alt-download.svg');
   }
   &--comment-alt-edit {
       background-image: url('../icons/comment-alt-edit.svg');
   }
   &--comment-alt-exclamation {
       background-image: url('../icons/comment-alt-exclamation.svg');
   }
   &--comment-alt-heart {
       background-image: url('../icons/comment-alt-heart.svg');
   }
   &--comment-alt-image {
       background-image: url('../icons/comment-alt-image.svg');
   }
   &--comment-alt-info {
       background-image: url('../icons/comment-alt-info.svg');
   }
   &--comment-alt-lines {
       background-image: url('../icons/comment-alt-lines.svg');
   }
   &--comment-alt-lock {
       background-image: url('../icons/comment-alt-lock.svg');
   }
   &--comment-alt-medical {
       background-image: url('../icons/comment-alt-medical.svg');
   }
   &--comment-alt-message {
       background-image: url('../icons/comment-alt-message.svg');
   }
   &--comment-alt-notes {
       background-image: url('../icons/comment-alt-notes.svg');
   }
   &--comment-alt-plus {
       background-image: url('../icons/comment-alt-plus.svg');
   }
   &--comment-alt-question {
       background-image: url('../icons/comment-alt-question.svg');
   }
   &--comment-alt-redo {
       background-image: url('../icons/comment-alt-redo.svg');
   }
   &--comment-alt-search {
       background-image: url('../icons/comment-alt-search.svg');
   }
   &--comment-alt-share {
       background-image: url('../icons/comment-alt-share.svg');
   }
   &--comment-alt-shield {
       background-image: url('../icons/comment-alt-shield.svg');
   }
   &--comment-alt-slash {
       background-image: url('../icons/comment-alt-slash.svg');
   }
   &--comment-alt-upload {
       background-image: url('../icons/comment-alt-upload.svg');
   }
   &--comment-alt-verify {
       background-image: url('../icons/comment-alt-verify.svg');
   }
   &--comment-alt {
       background-image: url('../icons/comment-alt.svg');
   }
   &--comment-block {
       background-image: url('../icons/comment-block.svg');
   }
   &--comment-chart-line {
       background-image: url('../icons/comment-chart-line.svg');
   }
   &--comment-check {
       background-image: url('../icons/comment-check.svg');
   }
   &--comment-dots {
       background-image: url('../icons/comment-dots.svg');
   }
   &--comment-download {
       background-image: url('../icons/comment-download.svg');
   }
   &--comment-edit {
       background-image: url('../icons/comment-edit.svg');
   }
   &--comment-exclamation {
       background-image: url('../icons/comment-exclamation.svg');
   }
   &--comment-heart {
       background-image: url('../icons/comment-heart.svg');
   }
   &--comment-image {
       background-image: url('../icons/comment-image.svg');
   }
   &--comment-info-alt {
       background-image: url('../icons/comment-info-alt.svg');
   }
   &--comment-info {
       background-image: url('../icons/comment-info.svg');
   }
   &--comment-lines {
       background-image: url('../icons/comment-lines.svg');
   }
   &--comment-lock {
       background-image: url('../icons/comment-lock.svg');
   }
   &--comment-medical {
       background-image: url('../icons/comment-medical.svg');
   }
   &--comment-message {
       background-image: url('../icons/comment-message.svg');
   }
   &--comment-notes {
       background-image: url('../icons/comment-notes.svg');
   }
   &--comment-plus {
       background-image: url('../icons/comment-plus.svg');
   }
   &--comment-question {
       background-image: url('../icons/comment-question.svg');
   }
   &--comment-redo {
       background-image: url('../icons/comment-redo.svg');
   }
   &--comment-search {
       background-image: url('../icons/comment-search.svg');
   }
   &--comment-share {
       background-image: url('../icons/comment-share.svg');
   }
   &--comment-shield {
       background-image: url('../icons/comment-shield.svg');
   }
   &--comment-slash {
       background-image: url('../icons/comment-slash.svg');
   }
   &--comment-upload {
       background-image: url('../icons/comment-upload.svg');
   }
   &--comment-verify {
       background-image: url('../icons/comment-verify.svg');
   }
   &--comment {
       background-image: url('../icons/comment.svg');
   }
   &--comments-alt {
       background-image: url('../icons/comments-alt.svg');
   }
   &--comments {
       background-image: url('../icons/comments.svg');
   }
   &--compact-disc {
       background-image: url('../icons/compact-disc.svg');
   }
   &--comparison {
       background-image: url('../icons/comparison.svg');
   }
   &--compass {
       background-image: url('../icons/compass.svg');
   }
   &--compress-alt-left {
       background-image: url('../icons/compress-alt-left.svg');
   }
   &--compress-alt {
       background-image: url('../icons/compress-alt.svg');
   }
   &--compress-arrows {
       background-image: url('../icons/compress-arrows.svg');
   }
   &--compress-lines {
       background-image: url('../icons/compress-lines.svg');
   }
   &--compress-point {
       background-image: url('../icons/compress-point.svg');
   }
   &--compress-v {
       background-image: url('../icons/compress-v.svg');
   }
   &--compress {
       background-image: url('../icons/compress.svg');
   }
   &--confused {
       background-image: url('../icons/confused.svg');
   }
   &--constructor {
       background-image: url('../icons/constructor.svg');
   }
   &--copy-alt {
       background-image: url('../icons/copy-alt.svg');
   }
   &--copy-landscape {
       background-image: url('../icons/copy-landscape.svg');
   }
   &--copy {
       background-image: url('../icons/copy.svg');
   }
   &--copyright {
       background-image: url('../icons/copyright.svg');
   }
   &--corner-down-left {
       background-image: url('../icons/corner-down-left.svg');
   }
   &--corner-down-right-alt {
       background-image: url('../icons/corner-down-right-alt.svg');
   }
   &--corner-down-right {
       background-image: url('../icons/corner-down-right.svg');
   }
   &--corner-left-down {
       background-image: url('../icons/corner-left-down.svg');
   }
   &--corner-right-down {
       background-image: url('../icons/corner-right-down.svg');
   }
   &--corner-up-left-alt {
       background-image: url('../icons/corner-up-left-alt.svg');
   }
   &--corner-up-left {
       background-image: url('../icons/corner-up-left.svg');
   }
   &--corner-up-right-alt {
       background-image: url('../icons/corner-up-right-alt.svg');
   }
   &--corner-up-right {
       background-image: url('../icons/corner-up-right.svg');
   }
   &--coronavirus {
       background-image: url('../icons/coronavirus.svg');
   }
   &--create-dashboard {
       background-image: url('../icons/create-dashboard.svg');
   }
   &--creative-commons-pd {
       background-image: url('../icons/creative-commons-pd.svg');
   }
   &--credit-card-search {
       background-image: url('../icons/credit-card-search.svg');
   }
   &--credit-card {
       background-image: url('../icons/credit-card.svg');
   }
   &--crockery {
       background-image: url('../icons/crockery.svg');
   }
   &--crop-alt-rotate-left {
       background-image: url('../icons/crop-alt-rotate-left.svg');
   }
   &--crop-alt-rotate-right {
       background-image: url('../icons/crop-alt-rotate-right.svg');
   }
   &--crop-alt {
       background-image: url('../icons/crop-alt.svg');
   }
   &--crosshair-alt {
       background-image: url('../icons/crosshair-alt.svg');
   }
   &--crosshair {
       background-image: url('../icons/crosshair.svg');
   }
   &--crosshairs {
       background-image: url('../icons/crosshairs.svg');
   }
   &--css3-simple {
       background-image: url('../icons/css3-simple.svg');
   }
   &--cube {
       background-image: url('../icons/cube.svg');
   }
   &--dashboard {
       background-image: url('../icons/dashboard.svg');
   }
   &--data-sharing {
       background-image: url('../icons/data-sharing.svg');
   }
   &--database-alt {
       background-image: url('../icons/database-alt.svg');
   }
   &--database {
       background-image: url('../icons/database.svg');
   }
   &--desert {
       background-image: url('../icons/desert.svg');
   }
   &--desktop-alt-slash {
       background-image: url('../icons/desktop-alt-slash.svg');
   }
   &--desktop-alt {
       background-image: url('../icons/desktop-alt.svg');
   }
   &--desktop-cloud-alt {
       background-image: url('../icons/desktop-cloud-alt.svg');
   }
   &--desktop-slash {
       background-image: url('../icons/desktop-slash.svg');
   }
   &--desktop {
       background-image: url('../icons/desktop.svg');
   }
   &--dialpad-alt {
       background-image: url('../icons/dialpad-alt.svg');
   }
   &--dialpad {
       background-image: url('../icons/dialpad.svg');
   }
   &--diamond {
       background-image: url('../icons/diamond.svg');
   }
   &--diary-alt {
       background-image: url('../icons/diary-alt.svg');
   }
   &--diary {
       background-image: url('../icons/diary.svg');
   }
   &--dice-five {
       background-image: url('../icons/dice-five.svg');
   }
   &--dice-four {
       background-image: url('../icons/dice-four.svg');
   }
   &--dice-one {
       background-image: url('../icons/dice-one.svg');
   }
   &--dice-six {
       background-image: url('../icons/dice-six.svg');
   }
   &--dice-three {
       background-image: url('../icons/dice-three.svg');
   }
   &--dice-two {
       background-image: url('../icons/dice-two.svg');
   }
   &--direction {
       background-image: url('../icons/direction.svg');
   }
   &--directions {
       background-image: url('../icons/directions.svg');
   }
   &--discord {
       background-image: url('../icons/discord.svg');
   }
   &--dizzy-meh {
       background-image: url('../icons/dizzy-meh.svg');
   }
   &--dna {
       background-image: url('../icons/dna.svg');
   }
   &--docker {
       background-image: url('../icons/docker.svg');
   }
   &--document-info {
       background-image: url('../icons/document-info.svg');
   }
   &--document-layout-center {
       background-image: url('../icons/document-layout-center.svg');
   }
   &--document-layout-left {
       background-image: url('../icons/document-layout-left.svg');
   }
   &--document-layout-right {
       background-image: url('../icons/document-layout-right.svg');
   }
   &--dollar-alt {
       background-image: url('../icons/dollar-alt.svg');
   }
   &--dollar-sign-alt {
       background-image: url('../icons/dollar-sign-alt.svg');
   }
   &--dollar-sign {
       background-image: url('../icons/dollar-sign.svg');
   }
   &--download-alt {
       background-image: url('../icons/download-alt.svg');
   }
   &--draggabledots {
       background-image: url('../icons/draggabledots.svg');
   }
   &--dribbble {
       background-image: url('../icons/dribbble.svg');
   }
   &--drill {
       background-image: url('../icons/drill.svg');
   }
   &--dropbox {
       background-image: url('../icons/dropbox.svg');
   }
   &--dumbbell {
       background-image: url('../icons/dumbbell.svg');
   }
   &--ear {
       background-image: url('../icons/ear.svg');
   }
   &--edit-alt {
       background-image: url('../icons/edit-alt.svg');
   }
   &--edit {
       background-image: url('../icons/edit.svg');
   }
   &--elipsis-double-v-alt {
       background-image: url('../icons/elipsis-double-v-alt.svg');
   }
   &--ellipsis-h {
       background-image: url('../icons/ellipsis-h.svg');
   }
   &--ellipsis-v {
       background-image: url('../icons/ellipsis-v.svg');
   }
   &--emoji {
       background-image: url('../icons/emoji.svg');
   }
   &--english-to-chinese {
       background-image: url('../icons/english-to-chinese.svg');
   }
   &--enter {
       background-image: url('../icons/enter.svg');
   }
   &--envelope-add {
       background-image: url('../icons/envelope-add.svg');
   }
   &--envelope-alt {
       background-image: url('../icons/envelope-alt.svg');
   }
   &--envelope-block {
       background-image: url('../icons/envelope-block.svg');
   }
   &--envelope-bookmark {
       background-image: url('../icons/envelope-bookmark.svg');
   }
   &--envelope-check {
       background-image: url('../icons/envelope-check.svg');
   }
   &--envelope-download-alt {
       background-image: url('../icons/envelope-download-alt.svg');
   }
   &--envelope-download {
       background-image: url('../icons/envelope-download.svg');
   }
   &--envelope-edit {
       background-image: url('../icons/envelope-edit.svg');
   }
   &--envelope-exclamation {
       background-image: url('../icons/envelope-exclamation.svg');
   }
   &--envelope-heart {
       background-image: url('../icons/envelope-heart.svg');
   }
   &--envelope-info {
       background-image: url('../icons/envelope-info.svg');
   }
   &--envelope-lock {
       background-image: url('../icons/envelope-lock.svg');
   }
   &--envelope-minus {
       background-image: url('../icons/envelope-minus.svg');
   }
   &--envelope-open {
       background-image: url('../icons/envelope-open.svg');
   }
   &--envelope-question {
       background-image: url('../icons/envelope-question.svg');
   }
   &--envelope-receive {
       background-image: url('../icons/envelope-receive.svg');
   }
   &--envelope-redo {
       background-image: url('../icons/envelope-redo.svg');
   }
   &--envelope-search {
       background-image: url('../icons/envelope-search.svg');
   }
   &--envelope-send {
       background-image: url('../icons/envelope-send.svg');
   }
   &--envelope-share {
       background-image: url('../icons/envelope-share.svg');
   }
   &--envelope-shield {
       background-image: url('../icons/envelope-shield.svg');
   }
   &--envelope-star {
       background-image: url('../icons/envelope-star.svg');
   }
   &--envelope-times {
       background-image: url('../icons/envelope-times.svg');
   }
   &--envelope-upload-alt {
       background-image: url('../icons/envelope-upload-alt.svg');
   }
   &--envelope-upload {
       background-image: url('../icons/envelope-upload.svg');
   }
   &--envelope {
       background-image: url('../icons/envelope.svg');
   }
   &--envelopes {
       background-image: url('../icons/envelopes.svg');
   }
   &--equal-circle {
       background-image: url('../icons/equal-circle.svg');
   }
   &--equal {
       background-image: url('../icons/equal.svg');
   }
   &--estate {
       background-image: url('../icons/estate.svg');
   }
   &--euro-circle {
       background-image: url('../icons/euro-circle.svg');
   }
   &--euro {
       background-image: url('../icons/euro.svg');
   }
   &--exchange-alt {
       background-image: url('../icons/exchange-alt.svg');
   }
   &--exchange {
       background-image: url('../icons/exchange.svg');
   }
   &--exclamation-circle {
       background-image: url('../icons/exclamation-circle.svg');
   }
   &--exclamation-octagon {
       background-image: url('../icons/exclamation-octagon.svg');
   }
   &--exclamation-triangle {
       background-image: url('../icons/exclamation-triangle.svg');
   }
   &--exclamation {
       background-image: url('../icons/exclamation.svg');
   }
   &--exclude {
       background-image: url('../icons/exclude.svg');
   }
   &--exit {
       background-image: url('../icons/exit.svg');
   }
   &--expand-alt {
       background-image: url('../icons/expand-alt.svg');
   }
   &--expand-arrows-alt {
       background-image: url('../icons/expand-arrows-alt.svg');
   }
   &--expand-arrows {
       background-image: url('../icons/expand-arrows.svg');
   }
   &--expand-from-corner {
       background-image: url('../icons/expand-from-corner.svg');
   }
   &--expand-left {
       background-image: url('../icons/expand-left.svg');
   }
   &--expand-right {
       background-image: url('../icons/expand-right.svg');
   }
   &--export {
       background-image: url('../icons/export.svg');
   }
   &--exposure-alt {
       background-image: url('../icons/exposure-alt.svg');
   }
   &--exposure-increase {
       background-image: url('../icons/exposure-increase.svg');
   }
   &--external-link-alt {
       background-image: url('../icons/external-link-alt.svg');
   }
   &--eye-slash {
       background-image: url('../icons/eye-slash.svg');
   }
   &--eye {
       background-image: url('../icons/eye.svg');
   }
   &--facebook-f {
       background-image: url('../icons/facebook-f.svg');
   }
   &--facebook-messenger-alt {
       background-image: url('../icons/facebook-messenger-alt.svg');
   }
   &--facebook-messenger {
       background-image: url('../icons/facebook-messenger.svg');
   }
   &--facebook {
       background-image: url('../icons/facebook.svg');
   }
   &--fahrenheit {
       background-image: url('../icons/fahrenheit.svg');
   }
   &--fast-mail-alt {
       background-image: url('../icons/fast-mail-alt.svg');
   }
   &--fast-mail {
       background-image: url('../icons/fast-mail.svg');
   }
   &--favorite {
       background-image: url('../icons/favorite.svg');
   }
   &--feedback {
       background-image: url('../icons/feedback.svg');
   }
   &--fidget-spinner {
       background-image: url('../icons/fidget-spinner.svg');
   }
   &--file-alt {
       background-image: url('../icons/file-alt.svg');
   }
   &--file-blank {
       background-image: url('../icons/file-blank.svg');
   }
   &--file-block-alt {
       background-image: url('../icons/file-block-alt.svg');
   }
   &--file-bookmark-alt {
       background-image: url('../icons/file-bookmark-alt.svg');
   }
   &--file-check-alt {
       background-image: url('../icons/file-check-alt.svg');
   }
   &--file-check {
       background-image: url('../icons/file-check.svg');
   }
   &--file-contract-dollar {
       background-image: url('../icons/file-contract-dollar.svg');
   }
   &--file-contract {
       background-image: url('../icons/file-contract.svg');
   }
   &--file-copy-alt {
       background-image: url('../icons/file-copy-alt.svg');
   }
   &--file-download-alt {
       background-image: url('../icons/file-download-alt.svg');
   }
   &--file-download {
       background-image: url('../icons/file-download.svg');
   }
   &--file-edit-alt {
       background-image: url('../icons/file-edit-alt.svg');
   }
   &--file-exclamation-alt {
       background-image: url('../icons/file-exclamation-alt.svg');
   }
   &--file-exclamation {
       background-image: url('../icons/file-exclamation.svg');
   }
   &--file-export {
       background-image: url('../icons/file-export.svg');
   }
   &--file-graph {
       background-image: url('../icons/file-graph.svg');
   }
   &--file-heart {
       background-image: url('../icons/file-heart.svg');
   }
   &--file-import {
       background-image: url('../icons/file-import.svg');
   }
   &--file-info-alt {
       background-image: url('../icons/file-info-alt.svg');
   }
   &--file-landscape-alt {
       background-image: url('../icons/file-landscape-alt.svg');
   }
   &--file-landscape {
       background-image: url('../icons/file-landscape.svg');
   }
   &--file-lanscape-slash {
       background-image: url('../icons/file-lanscape-slash.svg');
   }
   &--file-lock-alt {
       background-image: url('../icons/file-lock-alt.svg');
   }
   &--file-medical-alt {
       background-image: url('../icons/file-medical-alt.svg');
   }
   &--file-medical {
       background-image: url('../icons/file-medical.svg');
   }
   &--file-minus-alt {
       background-image: url('../icons/file-minus-alt.svg');
   }
   &--file-minus {
       background-image: url('../icons/file-minus.svg');
   }
   &--file-network {
       background-image: url('../icons/file-network.svg');
   }
   &--file-plus-alt {
       background-image: url('../icons/file-plus-alt.svg');
   }
   &--file-plus {
       background-image: url('../icons/file-plus.svg');
   }
   &--file-question-alt {
       background-image: url('../icons/file-question-alt.svg');
   }
   &--file-question {
       background-image: url('../icons/file-question.svg');
   }
   &--file-redo-alt {
       background-image: url('../icons/file-redo-alt.svg');
   }
   &--file-search-alt {
       background-image: url('../icons/file-search-alt.svg');
   }
   &--file-share-alt {
       background-image: url('../icons/file-share-alt.svg');
   }
   &--file-shield-alt {
       background-image: url('../icons/file-shield-alt.svg');
   }
   &--file-slash {
       background-image: url('../icons/file-slash.svg');
   }
   &--file-times-alt {
       background-image: url('../icons/file-times-alt.svg');
   }
   &--file-times {
       background-image: url('../icons/file-times.svg');
   }
   &--file-upload-alt {
       background-image: url('../icons/file-upload-alt.svg');
   }
   &--file-upload {
       background-image: url('../icons/file-upload.svg');
   }
   &--file {
       background-image: url('../icons/file.svg');
   }
   &--files-landscapes-alt {
       background-image: url('../icons/files-landscapes-alt.svg');
   }
   &--files-landscapes {
       background-image: url('../icons/files-landscapes.svg');
   }
   &--film {
       background-image: url('../icons/film.svg');
   }
   &--filter-slash {
       background-image: url('../icons/filter-slash.svg');
   }
   &--filter {
       background-image: url('../icons/filter.svg');
   }
   &--fire {
       background-image: url('../icons/fire.svg');
   }
   &--flask-potion {
       background-image: url('../icons/flask-potion.svg');
   }
   &--flask {
       background-image: url('../icons/flask.svg');
   }
   &--flip-h-alt {
       background-image: url('../icons/flip-h-alt.svg');
   }
   &--flip-h {
       background-image: url('../icons/flip-h.svg');
   }
   &--flip-v-alt {
       background-image: url('../icons/flip-v-alt.svg');
   }
   &--flip-v {
       background-image: url('../icons/flip-v.svg');
   }
   &--flower {
       background-image: url('../icons/flower.svg');
   }
   &--focus-add {
       background-image: url('../icons/focus-add.svg');
   }
   &--focus-target {
       background-image: url('../icons/focus-target.svg');
   }
   &--focus {
       background-image: url('../icons/focus.svg');
   }
   &--folder-check {
       background-image: url('../icons/folder-check.svg');
   }
   &--folder-download {
       background-image: url('../icons/folder-download.svg');
   }
   &--folder-exclamation {
       background-image: url('../icons/folder-exclamation.svg');
   }
   &--folder-heart {
       background-image: url('../icons/folder-heart.svg');
   }
   &--folder-info {
       background-image: url('../icons/folder-info.svg');
   }
   &--folder-lock {
       background-image: url('../icons/folder-lock.svg');
   }
   &--folder-medical {
       background-image: url('../icons/folder-medical.svg');
   }
   &--folder-minus {
       background-image: url('../icons/folder-minus.svg');
   }
   &--folder-network {
       background-image: url('../icons/folder-network.svg');
   }
   &--folder-open {
       background-image: url('../icons/folder-open.svg');
   }
   &--folder-plus {
       background-image: url('../icons/folder-plus.svg');
   }
   &--folder-question {
       background-image: url('../icons/folder-question.svg');
   }
   &--folder-slash {
       background-image: url('../icons/folder-slash.svg');
   }
   &--folder-times {
       background-image: url('../icons/folder-times.svg');
   }
   &--folder-upload {
       background-image: url('../icons/folder-upload.svg');
   }
   &--folder {
       background-image: url('../icons/folder.svg');
   }
   &--font {
       background-image: url('../icons/font.svg');
   }
   &--football-american {
       background-image: url('../icons/football-american.svg');
   }
   &--football-ball {
       background-image: url('../icons/football-ball.svg');
   }
   &--football {
       background-image: url('../icons/football.svg');
   }
   &--forecastcloud-moon-tear {
       background-image: url('../icons/forecastcloud-moon-tear.svg');
   }
   &--forwaded-call {
       background-image: url('../icons/forwaded-call.svg');
   }
   &--forward {
       background-image: url('../icons/forward.svg');
   }
   &--frown {
       background-image: url('../icons/frown.svg');
   }
   &--game-structure {
       background-image: url('../icons/game-structure.svg');
   }
   &--gift {
       background-image: url('../icons/gift.svg');
   }
   &--github-alt {
       background-image: url('../icons/github-alt.svg');
   }
   &--github {
       background-image: url('../icons/github.svg');
   }
   &--gitlab {
       background-image: url('../icons/gitlab.svg');
   }
   &--glass-martini-alt-slash {
       background-image: url('../icons/glass-martini-alt-slash.svg');
   }
   &--glass-martini-alt {
       background-image: url('../icons/glass-martini-alt.svg');
   }
   &--glass-martini {
       background-image: url('../icons/glass-martini.svg');
   }
   &--glass-tea {
       background-image: url('../icons/glass-tea.svg');
   }
   &--glass {
       background-image: url('../icons/glass.svg');
   }
   &--globe {
       background-image: url('../icons/globe.svg');
   }
   &--gold {
       background-image: url('../icons/gold.svg');
   }
   &--golf-ball {
       background-image: url('../icons/golf-ball.svg');
   }
   &--google-drive-alt {
       background-image: url('../icons/google-drive-alt.svg');
   }
   &--google-drive {
       background-image: url('../icons/google-drive.svg');
   }
   &--google-hangouts-alt {
       background-image: url('../icons/google-hangouts-alt.svg');
   }
   &--google-hangouts {
       background-image: url('../icons/google-hangouts.svg');
   }
   &--google-play {
       background-image: url('../icons/google-play.svg');
   }
   &--google {
       background-image: url('../icons/google.svg');
   }
   &--graduation-cap {
       background-image: url('../icons/graduation-cap.svg');
   }
   &--graph-bar {
       background-image: url('../icons/graph-bar.svg');
   }
   &--grid {
       background-image: url('../icons/grid.svg');
   }
   &--grids {
       background-image: url('../icons/grids.svg');
   }
   &--grin-tongue-wink-alt {
       background-image: url('../icons/grin-tongue-wink-alt.svg');
   }
   &--grin-tongue-wink {
       background-image: url('../icons/grin-tongue-wink.svg');
   }
   &--grin {
       background-image: url('../icons/grin.svg');
   }
   &--grip-horizontal-line {
       background-image: url('../icons/grip-horizontal-line.svg');
   }
   &--hamburger {
       background-image: url('../icons/hamburger.svg');
   }
   &--hard-hat {
       background-image: url('../icons/hard-hat.svg');
   }
   &--hdd {
       background-image: url('../icons/hdd.svg');
   }
   &--head-side-cough {
       background-image: url('../icons/head-side-cough.svg');
   }
   &--head-side-mask {
       background-image: url('../icons/head-side-mask.svg');
   }
   &--head-side {
       background-image: url('../icons/head-side.svg');
   }
   &--headphone-slash {
       background-image: url('../icons/headphone-slash.svg');
   }
   &--headphones-alt {
       background-image: url('../icons/headphones-alt.svg');
   }
   &--headphones {
       background-image: url('../icons/headphones.svg');
   }
   &--heart-alt {
       background-image: url('../icons/heart-alt.svg');
   }
   &--heart-break {
       background-image: url('../icons/heart-break.svg');
   }
   &--heart-medical {
       background-image: url('../icons/heart-medical.svg');
   }
   &--heart-rate {
       background-image: url('../icons/heart-rate.svg');
   }
   &--heart-sign {
       background-image: url('../icons/heart-sign.svg');
   }
   &--heart {
       background-image: url('../icons/heart.svg');
   }
   &--heartbeat {
       background-image: url('../icons/heartbeat.svg');
   }
   &--hindi-to-chinese {
       background-image: url('../icons/hindi-to-chinese.svg');
   }
   &--hipchat {
       background-image: url('../icons/hipchat.svg');
   }
   &--history-alt {
       background-image: url('../icons/history-alt.svg');
   }
   &--history {
       background-image: url('../icons/history.svg');
   }
   &--home-alt {
       background-image: url('../icons/home-alt.svg');
   }
   &--home {
       background-image: url('../icons/home.svg');
   }
   &--horizontal-align-center {
       background-image: url('../icons/horizontal-align-center.svg');
   }
   &--horizontal-align-left {
       background-image: url('../icons/horizontal-align-left.svg');
   }
   &--horizontal-align-right {
       background-image: url('../icons/horizontal-align-right.svg');
   }
   &--horizontal-distribution-center {
       background-image: url('../icons/horizontal-distribution-center.svg');
   }
   &--horizontal-distribution-left {
       background-image: url('../icons/horizontal-distribution-left.svg');
   }
   &--horizontal-distribution-right {
       background-image: url('../icons/horizontal-distribution-right.svg');
   }
   &--hospital-square-sign {
       background-image: url('../icons/hospital-square-sign.svg');
   }
   &--hospital-symbol {
       background-image: url('../icons/hospital-symbol.svg');
   }
   &--hospital {
       background-image: url('../icons/hospital.svg');
   }
   &--hourglass {
       background-image: url('../icons/hourglass.svg');
   }
   &--house-user {
       background-image: url('../icons/house-user.svg');
   }
   &--html3-alt {
       background-image: url('../icons/html3-alt.svg');
   }
   &--html3 {
       background-image: url('../icons/html3.svg');
   }
   &--html5-alt {
       background-image: url('../icons/html5-alt.svg');
   }
   &--html5 {
       background-image: url('../icons/html5.svg');
   }
   &--hunting {
       background-image: url('../icons/hunting.svg');
   }
   &--icons {
       background-image: url('../icons/icons.svg');
   }
   &--illustration {
       background-image: url('../icons/illustration.svg');
   }
   &--image-alt-slash {
       background-image: url('../icons/image-alt-slash.svg');
   }
   &--image-block {
       background-image: url('../icons/image-block.svg');
   }
   &--image-broken {
       background-image: url('../icons/image-broken.svg');
   }
   &--image-check {
       background-image: url('../icons/image-check.svg');
   }
   &--image-download {
       background-image: url('../icons/image-download.svg');
   }
   &--image-edit {
       background-image: url('../icons/image-edit.svg');
   }
   &--image-lock {
       background-image: url('../icons/image-lock.svg');
   }
   &--image-minus {
       background-image: url('../icons/image-minus.svg');
   }
   &--image-plus {
       background-image: url('../icons/image-plus.svg');
   }
   &--image-question {
       background-image: url('../icons/image-question.svg');
   }
   &--image-redo {
       background-image: url('../icons/image-redo.svg');
   }
   &--image-resize-landscape {
       background-image: url('../icons/image-resize-landscape.svg');
   }
   &--image-resize-square {
       background-image: url('../icons/image-resize-square.svg');
   }
   &--image-search {
       background-image: url('../icons/image-search.svg');
   }
   &--image-share {
       background-image: url('../icons/image-share.svg');
   }
   &--image-shield {
       background-image: url('../icons/image-shield.svg');
   }
   &--image-slash {
       background-image: url('../icons/image-slash.svg');
   }
   &--image-times {
       background-image: url('../icons/image-times.svg');
   }
   &--image-upload {
       background-image: url('../icons/image-upload.svg');
   }
   &--image-v {
       background-image: url('../icons/image-v.svg');
   }
   &--image {
       background-image: url('../icons/image.svg');
   }
   &--images {
       background-image: url('../icons/images.svg');
   }
   &--import {
       background-image: url('../icons/import.svg');
   }
   &--inbox {
       background-image: url('../icons/inbox.svg');
   }
   &--incoming-call {
       background-image: url('../icons/incoming-call.svg');
   }
   &--info-circle {
       background-image: url('../icons/info-circle.svg');
   }
   &--info {
       background-image: url('../icons/info.svg');
   }
   &--instagram-alt {
       background-image: url('../icons/instagram-alt.svg');
   }
   &--instagram {
       background-image: url('../icons/instagram.svg');
   }
   &--intercom-alt {
       background-image: url('../icons/intercom-alt.svg');
   }
   &--intercom {
       background-image: url('../icons/intercom.svg');
   }
   &--invoice {
       background-image: url('../icons/invoice.svg');
   }
   &--italic {
       background-image: url('../icons/italic.svg');
   }
   &--jackhammer {
       background-image: url('../icons/jackhammer.svg');
   }
   &--java-script {
       background-image: url('../icons/java-script.svg');
   }
   &--kayak {
       background-image: url('../icons/kayak.svg');
   }
   &--key-skeleton-alt {
       background-image: url('../icons/key-skeleton-alt.svg');
   }
   &--key-skeleton {
       background-image: url('../icons/key-skeleton.svg');
   }
   &--keyboard-alt {
       background-image: url('../icons/keyboard-alt.svg');
   }
   &--keyboard-hide {
       background-image: url('../icons/keyboard-hide.svg');
   }
   &--keyboard-show {
       background-image: url('../icons/keyboard-show.svg');
   }
   &--keyboard {
       background-image: url('../icons/keyboard.svg');
   }
   &--keyhole-circle {
       background-image: url('../icons/keyhole-circle.svg');
   }
   &--keyhole-square-full {
       background-image: url('../icons/keyhole-square-full.svg');
   }
   &--keyhole-square {
       background-image: url('../icons/keyhole-square.svg');
   }
   &--kid {
       background-image: url('../icons/kid.svg');
   }
   &--label-alt {
       background-image: url('../icons/label-alt.svg');
   }
   &--label {
       background-image: url('../icons/label.svg');
   }
   &--lamp {
       background-image: url('../icons/lamp.svg');
   }
   &--language {
       background-image: url('../icons/language.svg');
   }
   &--laptop-cloud {
       background-image: url('../icons/laptop-cloud.svg');
   }
   &--laptop-connection {
       background-image: url('../icons/laptop-connection.svg');
   }
   &--laptop {
       background-image: url('../icons/laptop.svg');
   }
   &--laughing {
       background-image: url('../icons/laughing.svg');
   }
   &--layer-group-slash {
       background-image: url('../icons/layer-group-slash.svg');
   }
   &--layer-group {
       background-image: url('../icons/layer-group.svg');
   }
   &--layers-alt {
       background-image: url('../icons/layers-alt.svg');
   }
   &--layers-slash {
       background-image: url('../icons/layers-slash.svg');
   }
   &--layers {
       background-image: url('../icons/layers.svg');
   }
   &--left-arrow-from-left {
       background-image: url('../icons/left-arrow-from-left.svg');
   }
   &--left-arrow-to-left {
       background-image: url('../icons/left-arrow-to-left.svg');
   }
   &--left-indent-alt {
       background-image: url('../icons/left-indent-alt.svg');
   }
   &--left-indent {
       background-image: url('../icons/left-indent.svg');
   }
   &--left-to-right-text-direction {
       background-image: url('../icons/left-to-right-text-direction.svg');
   }
   &--letter-chinese-a {
       background-image: url('../icons/letter-chinese-a.svg');
   }
   &--letter-english-a {
       background-image: url('../icons/letter-english-a.svg');
   }
   &--letter-hindi-a {
       background-image: url('../icons/letter-hindi-a.svg');
   }
   &--letter-japanese-a {
       background-image: url('../icons/letter-japanese-a.svg');
   }
   &--life-ring {
       background-image: url('../icons/life-ring.svg');
   }
   &--lightbulb-alt {
       background-image: url('../icons/lightbulb-alt.svg');
   }
   &--lightbulb {
       background-image: url('../icons/lightbulb.svg');
   }
   &--line-alt {
       background-image: url('../icons/line-alt.svg');
   }
   &--line-spacing {
       background-image: url('../icons/line-spacing.svg');
   }
   &--line {
       background-image: url('../icons/line.svg');
   }
   &--link-add {
       background-image: url('../icons/link-add.svg');
   }
   &--link-alt {
       background-image: url('../icons/link-alt.svg');
   }
   &--link-broken {
       background-image: url('../icons/link-broken.svg');
   }
   &--link-h {
       background-image: url('../icons/link-h.svg');
   }
   &--link {
       background-image: url('../icons/link.svg');
   }
   &--linkedin-alt {
       background-image: url('../icons/linkedin-alt.svg');
   }
   &--linkedin {
       background-image: url('../icons/linkedin.svg');
   }
   &--linux {
       background-image: url('../icons/linux.svg');
   }
   &--lira-sign {
       background-image: url('../icons/lira-sign.svg');
   }
   &--list-ol-alt {
       background-image: url('../icons/list-ol-alt.svg');
   }
   &--list-ol {
       background-image: url('../icons/list-ol.svg');
   }
   &--list-ui-alt {
       background-image: url('../icons/list-ui-alt.svg');
   }
   &--list-ul {
       background-image: url('../icons/list-ul.svg');
   }
   &--load {
       background-image: url('../icons/load.svg');
   }
   &--location-arrow-alt {
       background-image: url('../icons/location-arrow-alt.svg');
   }
   &--location-arrow {
       background-image: url('../icons/location-arrow.svg');
   }
   &--location-pin-alt {
       background-image: url('../icons/location-pin-alt.svg');
   }
   &--location-point {
       background-image: url('../icons/location-point.svg');
   }
   &--lock-access {
       background-image: url('../icons/lock-access.svg');
   }
   &--lock-alt {
       background-image: url('../icons/lock-alt.svg');
   }
   &--lock-open-alt {
       background-image: url('../icons/lock-open-alt.svg');
   }
   &--lock-slash {
       background-image: url('../icons/lock-slash.svg');
   }
   &--lock {
       background-image: url('../icons/lock.svg');
   }
   &--lottiefiles-alt {
       background-image: url('../icons/lottiefiles-alt.svg');
   }
   &--lottiefiles {
       background-image: url('../icons/lottiefiles.svg');
   }
   &--luggage-cart {
       background-image: url('../icons/luggage-cart.svg');
   }
   &--mailbox-alt {
       background-image: url('../icons/mailbox-alt.svg');
   }
   &--mailbox {
       background-image: url('../icons/mailbox.svg');
   }
   &--map-marker-alt {
       background-image: url('../icons/map-marker-alt.svg');
   }
   &--map-marker-edit {
       background-image: url('../icons/map-marker-edit.svg');
   }
   &--map-marker-info {
       background-image: url('../icons/map-marker-info.svg');
   }
   &--map-marker-minus {
       background-image: url('../icons/map-marker-minus.svg');
   }
   &--map-marker-plus {
       background-image: url('../icons/map-marker-plus.svg');
   }
   &--map-marker-question {
       background-image: url('../icons/map-marker-question.svg');
   }
   &--map-marker-shield {
       background-image: url('../icons/map-marker-shield.svg');
   }
   &--map-marker-slash {
       background-image: url('../icons/map-marker-slash.svg');
   }
   &--map-marker {
       background-image: url('../icons/map-marker.svg');
   }
   &--map-pin-alt {
       background-image: url('../icons/map-pin-alt.svg');
   }
   &--map-pin {
       background-image: url('../icons/map-pin.svg');
   }
   &--map {
       background-image: url('../icons/map.svg');
   }
   &--mars {
       background-image: url('../icons/mars.svg');
   }
   &--master-card {
       background-image: url('../icons/master-card.svg');
   }
   &--maximize-left {
       background-image: url('../icons/maximize-left.svg');
   }
   &--medal {
       background-image: url('../icons/medal.svg');
   }
   &--medical-drip {
       background-image: url('../icons/medical-drip.svg');
   }
   &--medical-square-full {
       background-image: url('../icons/medical-square-full.svg');
   }
   &--medical-square {
       background-image: url('../icons/medical-square.svg');
   }
   &--medium-m {
       background-image: url('../icons/medium-m.svg');
   }
   &--medkit {
       background-image: url('../icons/medkit.svg');
   }
   &--meeting-board {
       background-image: url('../icons/meeting-board.svg');
   }
   &--megaphone {
       background-image: url('../icons/megaphone.svg');
   }
   &--meh-alt {
       background-image: url('../icons/meh-alt.svg');
   }
   &--meh-closed-eye {
       background-image: url('../icons/meh-closed-eye.svg');
   }
   &--meh {
       background-image: url('../icons/meh.svg');
   }
   &--message {
       background-image: url('../icons/message.svg');
   }
   &--metro {
       background-image: url('../icons/metro.svg');
   }
   &--microphone-slash {
       background-image: url('../icons/microphone-slash.svg');
   }
   &--microphone {
       background-image: url('../icons/microphone.svg');
   }
   &--microscope {
       background-image: url('../icons/microscope.svg');
   }
   &--microsoft {
       background-image: url('../icons/microsoft.svg');
   }
   &--minus-circle {
       background-image: url('../icons/minus-circle.svg');
   }
   &--minus-path {
       background-image: url('../icons/minus-path.svg');
   }
   &--minus-square-full {
       background-image: url('../icons/minus-square-full.svg');
   }
   &--minus-square {
       background-image: url('../icons/minus-square.svg');
   }
   &--minus {
       background-image: url('../icons/minus.svg');
   }
   &--missed-call {
       background-image: url('../icons/missed-call.svg');
   }
   &--mobile-android-alt {
       background-image: url('../icons/mobile-android-alt.svg');
   }
   &--mobile-android {
       background-image: url('../icons/mobile-android.svg');
   }
   &--mobile-vibrate {
       background-image: url('../icons/mobile-vibrate.svg');
   }
   &--modem {
       background-image: url('../icons/modem.svg');
   }
   &--money-bill-slash {
       background-image: url('../icons/money-bill-slash.svg');
   }
   &--money-bill-stack {
       background-image: url('../icons/money-bill-stack.svg');
   }
   &--money-bill {
       background-image: url('../icons/money-bill.svg');
   }
   &--money-insert {
       background-image: url('../icons/money-insert.svg');
   }
   &--money-stack {
       background-image: url('../icons/money-stack.svg');
   }
   &--money-withdraw {
       background-image: url('../icons/money-withdraw.svg');
   }
   &--money-withdrawal {
       background-image: url('../icons/money-withdrawal.svg');
   }
   &--moneybag-alt {
       background-image: url('../icons/moneybag-alt.svg');
   }
   &--moneybag {
       background-image: url('../icons/moneybag.svg');
   }
   &--monitor-heart-rate {
       background-image: url('../icons/monitor-heart-rate.svg');
   }
   &--monitor {
       background-image: url('../icons/monitor.svg');
   }
   &--moon-eclipse {
       background-image: url('../icons/moon-eclipse.svg');
   }
   &--moon {
       background-image: url('../icons/moon.svg');
   }
   &--moonset {
       background-image: url('../icons/moonset.svg');
   }
   &--mountains-sun {
       background-image: url('../icons/mountains-sun.svg');
   }
   &--mountains {
       background-image: url('../icons/mountains.svg');
   }
   &--mouse-alt-2 {
       background-image: url('../icons/mouse-alt-2.svg');
   }
   &--mouse-alt {
       background-image: url('../icons/mouse-alt.svg');
   }
   &--mouse {
       background-image: url('../icons/mouse.svg');
   }
   &--multiply {
       background-image: url('../icons/multiply.svg');
   }
   &--music-note {
       background-image: url('../icons/music-note.svg');
   }
   &--music-tune-slash {
       background-image: url('../icons/music-tune-slash.svg');
   }
   &--music {
       background-image: url('../icons/music.svg');
   }
   &--n-a {
       background-image: url('../icons/n-a.svg');
   }
   &--navigator {
       background-image: url('../icons/navigator.svg');
   }
   &--nerd {
       background-image: url('../icons/nerd.svg');
   }
   &--newspaper {
       background-image: url('../icons/newspaper.svg');
   }
   &--ninja {
       background-image: url('../icons/ninja.svg');
   }
   &--no-entry {
       background-image: url('../icons/no-entry.svg');
   }
   &--notebooks {
       background-image: url('../icons/notebooks.svg');
   }
   &--notes {
       background-image: url('../icons/notes.svg');
   }
   &--object-group {
       background-image: url('../icons/object-group.svg');
   }
   &--object-ungroup {
       background-image: url('../icons/object-ungroup.svg');
   }
   &--octagon {
       background-image: url('../icons/octagon.svg');
   }
   &--okta {
       background-image: url('../icons/okta.svg');
   }
   &--opera-alt {
       background-image: url('../icons/opera-alt.svg');
   }
   &--opera {
       background-image: url('../icons/opera.svg');
   }
   &--outgoing-call {
       background-image: url('../icons/outgoing-call.svg');
   }
   &--package {
       background-image: url('../icons/package.svg');
   }
   &--padlock {
       background-image: url('../icons/padlock.svg');
   }
   &--pagelines {
       background-image: url('../icons/pagelines.svg');
   }
   &--pagerduty {
       background-image: url('../icons/pagerduty.svg');
   }
   &--paint-tool {
       background-image: url('../icons/paint-tool.svg');
   }
   &--palette {
       background-image: url('../icons/palette.svg');
   }
   &--panel-add {
       background-image: url('../icons/panel-add.svg');
   }
   &--panorama-h-alt {
       background-image: url('../icons/panorama-h-alt.svg');
   }
   &--panorama-h {
       background-image: url('../icons/panorama-h.svg');
   }
   &--panorama-v {
       background-image: url('../icons/panorama-v.svg');
   }
   &--paperclip {
       background-image: url('../icons/paperclip.svg');
   }
   &--paragraph {
       background-image: url('../icons/paragraph.svg');
   }
   &--parcel {
       background-image: url('../icons/parcel.svg');
   }
   &--parking-circle {
       background-image: url('../icons/parking-circle.svg');
   }
   &--parking-square {
       background-image: url('../icons/parking-square.svg');
   }
   &--pathfinder-unite {
       background-image: url('../icons/pathfinder-unite.svg');
   }
   &--pathfinder {
       background-image: url('../icons/pathfinder.svg');
   }
   &--pause-circle {
       background-image: url('../icons/pause-circle.svg');
   }
   &--pause {
       background-image: url('../icons/pause.svg');
   }
   &--paypal {
       background-image: url('../icons/paypal.svg');
   }
   &--pen {
       background-image: url('../icons/pen.svg');
   }
   &--pentagon {
       background-image: url('../icons/pentagon.svg');
   }
   &--percentage {
       background-image: url('../icons/percentage.svg');
   }
   &--phone-alt {
       background-image: url('../icons/phone-alt.svg');
   }
   &--phone-pause {
       background-image: url('../icons/phone-pause.svg');
   }
   &--phone-slash {
       background-image: url('../icons/phone-slash.svg');
   }
   &--phone-times {
       background-image: url('../icons/phone-times.svg');
   }
   &--phone-volume {
       background-image: url('../icons/phone-volume.svg');
   }
   &--phone {
       background-image: url('../icons/phone.svg');
   }
   &--picture {
       background-image: url('../icons/picture.svg');
   }
   &--pizza-slice {
       background-image: url('../icons/pizza-slice.svg');
   }
   &--plane-arrival {
       background-image: url('../icons/plane-arrival.svg');
   }
   &--plane-departure {
       background-image: url('../icons/plane-departure.svg');
   }
   &--plane-fly {
       background-image: url('../icons/plane-fly.svg');
   }
   &--plane {
       background-image: url('../icons/plane.svg');
   }
   &--play-circle {
       background-image: url('../icons/play-circle.svg');
   }
   &--play {
       background-image: url('../icons/play.svg');
   }
   &--plug {
       background-image: url('../icons/plug.svg');
   }
   &--plus-circle {
       background-image: url('../icons/plus-circle.svg');
   }
   &--plus-square {
       background-image: url('../icons/plus-square.svg');
   }
   &--plus {
       background-image: url('../icons/plus.svg');
   }
   &--podium {
       background-image: url('../icons/podium.svg');
   }
   &--polygon {
       background-image: url('../icons/polygon.svg');
   }
   &--post-stamp {
       background-image: url('../icons/post-stamp.svg');
   }
   &--postcard {
       background-image: url('../icons/postcard.svg');
   }
   &--pound-circle {
       background-image: url('../icons/pound-circle.svg');
   }
   &--pound {
       background-image: url('../icons/pound.svg');
   }
   &--power {
       background-image: url('../icons/power.svg');
   }
   &--prescription-bottle {
       background-image: url('../icons/prescription-bottle.svg');
   }
   &--presentation-check {
       background-image: url('../icons/presentation-check.svg');
   }
   &--presentation-edit {
       background-image: url('../icons/presentation-edit.svg');
   }
   &--presentation-line {
       background-image: url('../icons/presentation-line.svg');
   }
   &--presentation-lines-alt {
       background-image: url('../icons/presentation-lines-alt.svg');
   }
   &--presentation-minus {
       background-image: url('../icons/presentation-minus.svg');
   }
   &--presentation-play {
       background-image: url('../icons/presentation-play.svg');
   }
   &--presentation-plus {
       background-image: url('../icons/presentation-plus.svg');
   }
   &--presentation-times {
       background-image: url('../icons/presentation-times.svg');
   }
   &--presentation {
       background-image: url('../icons/presentation.svg');
   }
   &--previous {
       background-image: url('../icons/previous.svg');
   }
   &--pricetag-alt {
       background-image: url('../icons/pricetag-alt.svg');
   }
   &--print-slash {
       background-image: url('../icons/print-slash.svg');
   }
   &--print {
       background-image: url('../icons/print.svg');
   }
   &--process {
       background-image: url('../icons/process.svg');
   }
   &--processor {
       background-image: url('../icons/processor.svg');
   }
   &--programming-language {
       background-image: url('../icons/programming-language.svg');
   }
   &--pump {
       background-image: url('../icons/pump.svg');
   }
   &--puzzle-piece {
       background-image: url('../icons/puzzle-piece.svg');
   }
   &--qrcode-scan {
       background-image: url('../icons/qrcode-scan.svg');
   }
   &--question-circle {
       background-image: url('../icons/question-circle.svg');
   }
   &--question {
       background-image: url('../icons/question.svg');
   }
   &--rainbow {
       background-image: url('../icons/rainbow.svg');
   }
   &--raindrops-alt {
       background-image: url('../icons/raindrops-alt.svg');
   }
   &--raindrops {
       background-image: url('../icons/raindrops.svg');
   }
   &--react {
       background-image: url('../icons/react.svg');
   }
   &--receipt-alt {
       background-image: url('../icons/receipt-alt.svg');
   }
   &--receipt {
       background-image: url('../icons/receipt.svg');
   }
   &--record-audio {
       background-image: url('../icons/record-audio.svg');
   }
   &--reddit-alien-alt {
       background-image: url('../icons/reddit-alien-alt.svg');
   }
   &--redo {
       background-image: url('../icons/redo.svg');
   }
   &--refresh {
       background-image: url('../icons/refresh.svg');
   }
   &--registered {
       background-image: url('../icons/registered.svg');
   }
   &--repeat {
       background-image: url('../icons/repeat.svg');
   }
   &--restaurant {
       background-image: url('../icons/restaurant.svg');
   }
   &--right-indent-alt {
       background-image: url('../icons/right-indent-alt.svg');
   }
   &--right-to-left-text-direction {
       background-image: url('../icons/right-to-left-text-direction.svg');
   }
   &--robot {
       background-image: url('../icons/robot.svg');
   }
   &--rocket {
       background-image: url('../icons/rocket.svg');
   }
   &--rope-way {
       background-image: url('../icons/rope-way.svg');
   }
   &--rotate-360 {
       background-image: url('../icons/rotate-360.svg');
   }
   &--rss-alt {
       background-image: url('../icons/rss-alt.svg');
   }
   &--rss-interface {
       background-image: url('../icons/rss-interface.svg');
   }
   &--rss {
       background-image: url('../icons/rss.svg');
   }
   &--ruler-combined {
       background-image: url('../icons/ruler-combined.svg');
   }
   &--ruler {
       background-image: url('../icons/ruler.svg');
   }
   &--rupee-sign {
       background-image: url('../icons/rupee-sign.svg');
   }
   &--sad-cry {
       background-image: url('../icons/sad-cry.svg');
   }
   &--sad-crying {
       background-image: url('../icons/sad-crying.svg');
   }
   &--sad-dizzy {
       background-image: url('../icons/sad-dizzy.svg');
   }
   &--sad-squint {
       background-image: url('../icons/sad-squint.svg');
   }
   &--sad {
       background-image: url('../icons/sad.svg');
   }
   &--sanitizer-alt {
       background-image: url('../icons/sanitizer-alt.svg');
   }
   &--sanitizer {
       background-image: url('../icons/sanitizer.svg');
   }
   &--save {
       background-image: url('../icons/save.svg');
   }
   &--scaling-left {
       background-image: url('../icons/scaling-left.svg');
   }
   &--scaling-right {
       background-image: url('../icons/scaling-right.svg');
   }
   &--scenery {
       background-image: url('../icons/scenery.svg');
   }
   &--schedule {
       background-image: url('../icons/schedule.svg');
   }
   &--screw {
       background-image: url('../icons/screw.svg');
   }
   &--scroll-h {
       background-image: url('../icons/scroll-h.svg');
   }
   &--scroll {
       background-image: url('../icons/scroll.svg');
   }
   &--search-alt {
       background-image: url('../icons/search-alt.svg');
   }
   &--search-minus {
       background-image: url('../icons/search-minus.svg');
   }
   &--search-plus {
       background-image: url('../icons/search-plus.svg');
   }
   &--search {
       background-image: url('../icons/search.svg');
   }
   &--selfie {
       background-image: url('../icons/selfie.svg');
   }
   &--server-alt {
       background-image: url('../icons/server-alt.svg');
   }
   &--server-connection {
       background-image: url('../icons/server-connection.svg');
   }
   &--server-network-alt {
       background-image: url('../icons/server-network-alt.svg');
   }
   &--server-network {
       background-image: url('../icons/server-network.svg');
   }
   &--server {
       background-image: url('../icons/server.svg');
   }
   &--servers {
       background-image: url('../icons/servers.svg');
   }
   &--servicemark {
       background-image: url('../icons/servicemark.svg');
   }
   &--setting {
       background-image: url('../icons/setting.svg');
   }
   &--share-alt {
       background-image: url('../icons/share-alt.svg');
   }
   &--share {
       background-image: url('../icons/share.svg');
   }
   &--shield-check {
       background-image: url('../icons/shield-check.svg');
   }
   &--shield-exclamation {
       background-image: url('../icons/shield-exclamation.svg');
   }
   &--shield-plus {
       background-image: url('../icons/shield-plus.svg');
   }
   &--shield-question {
       background-image: url('../icons/shield-question.svg');
   }
   &--shield-slash {
       background-image: url('../icons/shield-slash.svg');
   }
   &--shield {
       background-image: url('../icons/shield.svg');
   }
   &--ship {
       background-image: url('../icons/ship.svg');
   }
   &--shop {
       background-image: url('../icons/shop.svg');
   }
   &--shopping-bag {
       background-image: url('../icons/shopping-bag.svg');
   }
   &--shopping-basket {
       background-image: url('../icons/shopping-basket.svg');
   }
   &--shopping-cart-alt {
       background-image: url('../icons/shopping-cart-alt.svg');
   }
   &--shopping-cart {
       background-image: url('../icons/shopping-cart.svg');
   }
   &--shovel {
       background-image: url('../icons/shovel.svg');
   }
   &--shrink {
       background-image: url('../icons/shrink.svg');
   }
   &--shuffle {
       background-image: url('../icons/shuffle.svg');
   }
   &--shutter-alt {
       background-image: url('../icons/shutter-alt.svg');
   }
   &--shutter {
       background-image: url('../icons/shutter.svg');
   }
   &--sick {
       background-image: url('../icons/sick.svg');
   }
   &--sigma {
       background-image: url('../icons/sigma.svg');
   }
   &--sign-alt {
       background-image: url('../icons/sign-alt.svg');
   }
   &--sign-in-alt {
       background-image: url('../icons/sign-in-alt.svg');
   }
   &--sign-left {
       background-image: url('../icons/sign-left.svg');
   }
   &--sign-out-alt {
       background-image: url('../icons/sign-out-alt.svg');
   }
   &--sign-right {
       background-image: url('../icons/sign-right.svg');
   }
   &--signal-alt-3 {
       background-image: url('../icons/signal-alt-3.svg');
   }
   &--signal-alt {
       background-image: url('../icons/signal-alt.svg');
   }
   &--signal {
       background-image: url('../icons/signal.svg');
   }
   &--signin {
       background-image: url('../icons/signin.svg');
   }
   &--signout {
       background-image: url('../icons/signout.svg');
   }
   &--silence {
       background-image: url('../icons/silence.svg');
   }
   &--silent-squint {
       background-image: url('../icons/silent-squint.svg');
   }
   &--sim-card {
       background-image: url('../icons/sim-card.svg');
   }
   &--sitemap {
       background-image: url('../icons/sitemap.svg');
   }
   &--skip-forward-alt {
       background-image: url('../icons/skip-forward-alt.svg');
   }
   &--skip-forward-circle {
       background-image: url('../icons/skip-forward-circle.svg');
   }
   &--skip-forward {
       background-image: url('../icons/skip-forward.svg');
   }
   &--skype-alt {
       background-image: url('../icons/skype-alt.svg');
   }
   &--skype {
       background-image: url('../icons/skype.svg');
   }
   &--slack-alt {
       background-image: url('../icons/slack-alt.svg');
   }
   &--slack {
       background-image: url('../icons/slack.svg');
   }
   &--slider-h-range {
       background-image: url('../icons/slider-h-range.svg');
   }
   &--slider-h {
       background-image: url('../icons/slider-h.svg');
   }
   &--sliders-v-alt {
       background-image: url('../icons/sliders-v-alt.svg');
   }
   &--sliders-v {
       background-image: url('../icons/sliders-v.svg');
   }
   &--smile-beam {
       background-image: url('../icons/smile-beam.svg');
   }
   &--smile-dizzy {
       background-image: url('../icons/smile-dizzy.svg');
   }
   &--smile-squint-wink-alt {
       background-image: url('../icons/smile-squint-wink-alt.svg');
   }
   &--smile-squint-wink {
       background-image: url('../icons/smile-squint-wink.svg');
   }
   &--smile-wink-alt {
       background-image: url('../icons/smile-wink-alt.svg');
   }
   &--smile-wink {
       background-image: url('../icons/smile-wink.svg');
   }
   &--smile {
       background-image: url('../icons/smile.svg');
   }
   &--snapchat-alt {
       background-image: url('../icons/snapchat-alt.svg');
   }
   &--snapchat-ghost {
       background-image: url('../icons/snapchat-ghost.svg');
   }
   &--snapchat-square {
       background-image: url('../icons/snapchat-square.svg');
   }
   &--snow-flake {
       background-image: url('../icons/snow-flake.svg');
   }
   &--snowflake-alt {
       background-image: url('../icons/snowflake-alt.svg');
   }
   &--snowflake {
       background-image: url('../icons/snowflake.svg');
   }
   &--social-distancing {
       background-image: url('../icons/social-distancing.svg');
   }
   &--sort-amount-down {
       background-image: url('../icons/sort-amount-down.svg');
   }
   &--sort-amount-up {
       background-image: url('../icons/sort-amount-up.svg');
   }
   &--sort {
       background-image: url('../icons/sort.svg');
   }
   &--sorting {
       background-image: url('../icons/sorting.svg');
   }
   &--space-key {
       background-image: url('../icons/space-key.svg');
   }
   &--spade {
       background-image: url('../icons/spade.svg');
   }
   &--sperms {
       background-image: url('../icons/sperms.svg');
   }
   &--spin {
       background-image: url('../icons/spin.svg');
   }
   &--spinner-alt {
       background-image: url('../icons/spinner-alt.svg');
   }
   &--spinner {
       background-image: url('../icons/spinner.svg');
   }
   &--spotify {
       background-image: url('../icons/spotify.svg');
   }
   &--square-full {
       background-image: url('../icons/square-full.svg');
   }
   &--square-shape {
       background-image: url('../icons/square-shape.svg');
   }
   &--square {
       background-image: url('../icons/square.svg');
   }
   &--squint {
       background-image: url('../icons/squint.svg');
   }
   &--star-half-alt {
       background-image: url('../icons/star-half-alt.svg');
   }
   &--star {
       background-image: url('../icons/star.svg');
   }
   &--step-backward-alt {
       background-image: url('../icons/step-backward-alt.svg');
   }
   &--step-backward-circle {
       background-image: url('../icons/step-backward-circle.svg');
   }
   &--step-backward {
       background-image: url('../icons/step-backward.svg');
   }
   &--step-forward {
       background-image: url('../icons/step-forward.svg');
   }
   &--stethoscope-alt {
       background-image: url('../icons/stethoscope-alt.svg');
   }
   &--stethoscope {
       background-image: url('../icons/stethoscope.svg');
   }
   &--stop-circle {
       background-image: url('../icons/stop-circle.svg');
   }
   &--stopwatch-slash {
       background-image: url('../icons/stopwatch-slash.svg');
   }
   &--stopwatch {
       background-image: url('../icons/stopwatch.svg');
   }
   &--store-alt {
       background-image: url('../icons/store-alt.svg');
   }
   &--store-slash {
       background-image: url('../icons/store-slash.svg');
   }
   &--store {
       background-image: url('../icons/store.svg');
   }
   &--streering {
       background-image: url('../icons/streering.svg');
   }
   &--stretcher {
       background-image: url('../icons/stretcher.svg');
   }
   &--subject {
       background-image: url('../icons/subject.svg');
   }
   &--subway-alt {
       background-image: url('../icons/subway-alt.svg');
   }
   &--subway {
       background-image: url('../icons/subway.svg');
   }
   &--suitcase-alt {
       background-image: url('../icons/suitcase-alt.svg');
   }
   &--suitcase {
       background-image: url('../icons/suitcase.svg');
   }
   &--sun {
       background-image: url('../icons/sun.svg');
   }
   &--sunset {
       background-image: url('../icons/sunset.svg');
   }
   &--surprise {
       background-image: url('../icons/surprise.svg');
   }
   &--swatchbook {
       background-image: url('../icons/swatchbook.svg');
   }
   &--swiggy {
       background-image: url('../icons/swiggy.svg');
   }
   &--swimmer {
       background-image: url('../icons/swimmer.svg');
   }
   &--sync-exclamation {
       background-image: url('../icons/sync-exclamation.svg');
   }
   &--sync-slash {
       background-image: url('../icons/sync-slash.svg');
   }
   &--sync {
       background-image: url('../icons/sync.svg');
   }
   &--syringe {
       background-image: url('../icons/syringe.svg');
   }
   &--table-tennis {
       background-image: url('../icons/table-tennis.svg');
   }
   &--table {
       background-image: url('../icons/table.svg');
   }
   &--tablet {
       background-image: url('../icons/tablet.svg');
   }
   &--tablets {
       background-image: url('../icons/tablets.svg');
   }
   &--tachometer-fast-alt {
       background-image: url('../icons/tachometer-fast-alt.svg');
   }
   &--tachometer-fast {
       background-image: url('../icons/tachometer-fast.svg');
   }
   &--tag-alt {
       background-image: url('../icons/tag-alt.svg');
   }
   &--tag {
       background-image: url('../icons/tag.svg');
   }
   &--tape {
       background-image: url('../icons/tape.svg');
   }
   &--taxi {
       background-image: url('../icons/taxi.svg');
   }
   &--tear {
       background-image: url('../icons/tear.svg');
   }
   &--telegram-alt {
       background-image: url('../icons/telegram-alt.svg');
   }
   &--telegram {
       background-image: url('../icons/telegram.svg');
   }
   &--telescope {
       background-image: url('../icons/telescope.svg');
   }
   &--temperature-empty {
       background-image: url('../icons/temperature-empty.svg');
   }
   &--temperature-half {
       background-image: url('../icons/temperature-half.svg');
   }
   &--temperature-minus {
       background-image: url('../icons/temperature-minus.svg');
   }
   &--temperature-plus {
       background-image: url('../icons/temperature-plus.svg');
   }
   &--temperature-quarter {
       background-image: url('../icons/temperature-quarter.svg');
   }
   &--temperature-three-quarter {
       background-image: url('../icons/temperature-three-quarter.svg');
   }
   &--temperature {
       background-image: url('../icons/temperature.svg');
   }
   &--tennis-ball {
       background-image: url('../icons/tennis-ball.svg');
   }
   &--text-fields {
       background-image: url('../icons/text-fields.svg');
   }
   &--text-size {
       background-image: url('../icons/text-size.svg');
   }
   &--text-strike-through {
       background-image: url('../icons/text-strike-through.svg');
   }
   &--text {
       background-image: url('../icons/text.svg');
   }
   &--th-large {
       background-image: url('../icons/th-large.svg');
   }
   &--th-slash {
       background-image: url('../icons/th-slash.svg');
   }
   &--th {
       background-image: url('../icons/th.svg');
   }
   &--thermometer {
       background-image: url('../icons/thermometer.svg');
   }
   &--thumbs-down {
       background-image: url('../icons/thumbs-down.svg');
   }
   &--thumbs-up {
       background-image: url('../icons/thumbs-up.svg');
   }
   &--thunderstorm-moon {
       background-image: url('../icons/thunderstorm-moon.svg');
   }
   &--thunderstorm-sun {
       background-image: url('../icons/thunderstorm-sun.svg');
   }
   &--thunderstorm {
       background-image: url('../icons/thunderstorm.svg');
   }
   &--ticket {
       background-image: url('../icons/ticket.svg');
   }
   &--times-circle {
       background-image: url('../icons/times-circle.svg');
   }
   &--times-square {
       background-image: url('../icons/times-square.svg');
   }
   &--times {
       background-image: url('../icons/times.svg');
   }
   &--toggle-off {
       background-image: url('../icons/toggle-off.svg');
   }
   &--toggle-on {
       background-image: url('../icons/toggle-on.svg');
   }
   &--toilet-paper {
       background-image: url('../icons/toilet-paper.svg');
   }
   &--top-arrow-from-top {
       background-image: url('../icons/top-arrow-from-top.svg');
   }
   &--top-arrow-to-top {
       background-image: url('../icons/top-arrow-to-top.svg');
   }
   &--tornado {
       background-image: url('../icons/tornado.svg');
   }
   &--trademark-circle {
       background-image: url('../icons/trademark-circle.svg');
   }
   &--trademark {
       background-image: url('../icons/trademark.svg');
   }
   &--traffic-barrier {
       background-image: url('../icons/traffic-barrier.svg');
   }
   &--traffic-light {
       background-image: url('../icons/traffic-light.svg');
   }
   &--transaction {
       background-image: url('../icons/transaction.svg');
   }
   &--trash-alt {
       background-image: url('../icons/trash-alt.svg');
   }
   &--trash {
       background-image: url('../icons/trash.svg');
   }
   &--trees {
       background-image: url('../icons/trees.svg');
   }
   &--triangle {
       background-image: url('../icons/triangle.svg');
   }
   &--trophy {
       background-image: url('../icons/trophy.svg');
   }
   &--trowel {
       background-image: url('../icons/trowel.svg');
   }
   &--truck-loading {
       background-image: url('../icons/truck-loading.svg');
   }
   &--truck {
       background-image: url('../icons/truck.svg');
   }
   &--tumblr-alt {
       background-image: url('../icons/tumblr-alt.svg');
   }
   &--tumblr-square {
       background-image: url('../icons/tumblr-square.svg');
   }
   &--tumblr {
       background-image: url('../icons/tumblr.svg');
   }
   &--tv-retro-slash {
       background-image: url('../icons/tv-retro-slash.svg');
   }
   &--tv-retro {
       background-image: url('../icons/tv-retro.svg');
   }
   &--twitter-alt {
       background-image: url('../icons/twitter-alt.svg');
   }
   &--twitter {
       background-image: url('../icons/twitter.svg');
   }
   &--umbrella {
       background-image: url('../icons/umbrella.svg');
   }
   &--unamused {
       background-image: url('../icons/unamused.svg');
   }
   &--underline {
       background-image: url('../icons/underline.svg');
   }
   &--university {
       background-image: url('../icons/university.svg');
   }
   &--unlock-alt {
       background-image: url('../icons/unlock-alt.svg');
   }
   &--unlock {
       background-image: url('../icons/unlock.svg');
   }
   &--upload-alt {
       background-image: url('../icons/upload-alt.svg');
   }
   &--upload {
       background-image: url('../icons/upload.svg');
   }
   &--usd-circle {
       background-image: url('../icons/usd-circle.svg');
   }
   &--usd-square {
       background-image: url('../icons/usd-square.svg');
   }
   &--user-arrows {
       background-image: url('../icons/user-arrows.svg');
   }
   &--user-check {
       background-image: url('../icons/user-check.svg');
   }
   &--user-circle {
       background-image: url('../icons/user-circle.svg');
   }
   &--user-exclamation {
       background-image: url('../icons/user-exclamation.svg');
   }
   &--user-location {
       background-image: url('../icons/user-location.svg');
   }
   &--user-md {
       background-image: url('../icons/user-md.svg');
   }
   &--user-minus {
       background-image: url('../icons/user-minus.svg');
   }
   &--user-nurse {
       background-image: url('../icons/user-nurse.svg');
   }
   &--user-plus {
       background-image: url('../icons/user-plus.svg');
   }
   &--user-square {
       background-image: url('../icons/user-square.svg');
   }
   &--user-times {
       background-image: url('../icons/user-times.svg');
   }
   &--user {
       background-image: url('../icons/user.svg');
   }
   &--users-alt {
       background-image: url('../icons/users-alt.svg');
   }
   &--utensils-alt {
       background-image: url('../icons/utensils-alt.svg');
   }
   &--utensils {
       background-image: url('../icons/utensils.svg');
   }
   &--vector-square-alt {
       background-image: url('../icons/vector-square-alt.svg');
   }
   &--vector-square {
       background-image: url('../icons/vector-square.svg');
   }
   &--venus {
       background-image: url('../icons/venus.svg');
   }
   &--vertical-align-bottom {
       background-image: url('../icons/vertical-align-bottom.svg');
   }
   &--vertical-align-center {
       background-image: url('../icons/vertical-align-center.svg');
   }
   &--vertical-align-top {
       background-image: url('../icons/vertical-align-top.svg');
   }
   &--vertical-distribute-bottom {
       background-image: url('../icons/vertical-distribute-bottom.svg');
   }
   &--vertical-distribution-center {
       background-image: url('../icons/vertical-distribution-center.svg');
   }
   &--vertical-distribution-top {
       background-image: url('../icons/vertical-distribution-top.svg');
   }
   &--video-question {
       background-image: url('../icons/video-question.svg');
   }
   &--video-slash {
       background-image: url('../icons/video-slash.svg');
   }
   &--video {
       background-image: url('../icons/video.svg');
   }
   &--virus-slash {
       background-image: url('../icons/virus-slash.svg');
   }
   &--visual-studio {
       background-image: url('../icons/visual-studio.svg');
   }
   &--vk-alt {
       background-image: url('../icons/vk-alt.svg');
   }
   &--vk {
       background-image: url('../icons/vk.svg');
   }
   &--voicemail-rectangle {
       background-image: url('../icons/voicemail-rectangle.svg');
   }
   &--voicemail {
       background-image: url('../icons/voicemail.svg');
   }
   &--volume-down {
       background-image: url('../icons/volume-down.svg');
   }
   &--volume-mute {
       background-image: url('../icons/volume-mute.svg');
   }
   &--volume-off {
       background-image: url('../icons/volume-off.svg');
   }
   &--volume-up {
       background-image: url('../icons/volume-up.svg');
   }
   &--volume {
       background-image: url('../icons/volume.svg');
   }
   &--vuejs-alt {
       background-image: url('../icons/vuejs-alt.svg');
   }
   &--vuejs {
       background-image: url('../icons/vuejs.svg');
   }
   &--wall {
       background-image: url('../icons/wall.svg');
   }
   &--wallet {
       background-image: url('../icons/wallet.svg');
   }
   &--watch-alt {
       background-image: url('../icons/watch-alt.svg');
   }
   &--watch {
       background-image: url('../icons/watch.svg');
   }
   &--water-drop-slash {
       background-image: url('../icons/water-drop-slash.svg');
   }
   &--water-glass {
       background-image: url('../icons/water-glass.svg');
   }
   &--water {
       background-image: url('../icons/water.svg');
   }
   &--web-grid-alt {
       background-image: url('../icons/web-grid-alt.svg');
   }
   &--web-grid {
       background-image: url('../icons/web-grid.svg');
   }
   &--web-section-alt {
       background-image: url('../icons/web-section-alt.svg');
   }
   &--web-section {
       background-image: url('../icons/web-section.svg');
   }
   &--webcam {
       background-image: url('../icons/webcam.svg');
   }
   &--weight {
       background-image: url('../icons/weight.svg');
   }
   &--whatsapp-alt {
       background-image: url('../icons/whatsapp-alt.svg');
   }
   &--whatsapp {
       background-image: url('../icons/whatsapp.svg');
   }
   &--wheel-barrow {
       background-image: url('../icons/wheel-barrow.svg');
   }
   &--wheelchair-alt {
       background-image: url('../icons/wheelchair-alt.svg');
   }
   &--wheelchair {
       background-image: url('../icons/wheelchair.svg');
   }
   &--wifi-router {
       background-image: url('../icons/wifi-router.svg');
   }
   &--wifi-slash {
       background-image: url('../icons/wifi-slash.svg');
   }
   &--wifi {
       background-image: url('../icons/wifi.svg');
   }
   &--wind-moon {
       background-image: url('../icons/wind-moon.svg');
   }
   &--wind-sun {
       background-image: url('../icons/wind-sun.svg');
   }
   &--wind {
       background-image: url('../icons/wind.svg');
   }
   &--window-grid {
       background-image: url('../icons/window-grid.svg');
   }
   &--window-maximize {
       background-image: url('../icons/window-maximize.svg');
   }
   &--window-section {
       background-image: url('../icons/window-section.svg');
   }
   &--window {
       background-image: url('../icons/window.svg');
   }
   &--windows {
       background-image: url('../icons/windows.svg');
   }
   &--windsock {
       background-image: url('../icons/windsock.svg');
   }
   &--windy {
       background-image: url('../icons/windy.svg');
   }
   &--wordpress-simple {
       background-image: url('../icons/wordpress-simple.svg');
   }
   &--wordpress {
       background-image: url('../icons/wordpress.svg');
   }
   &--wrap-text {
       background-image: url('../icons/wrap-text.svg');
   }
   &--wrench {
       background-image: url('../icons/wrench.svg');
   }
   &--x-add {
       background-image: url('../icons/x-add.svg');
   }
   &--x {
       background-image: url('../icons/x.svg');
   }
   &--yen-circle {
       background-image: url('../icons/yen-circle.svg');
   }
   &--yen {
       background-image: url('../icons/yen.svg');
   }
   &--yin-yang {
       background-image: url('../icons/yin-yang.svg');
   }
   &--youtube {
       background-image: url('../icons/youtube.svg');
   }
}
